import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import { API } from 'services';

const initialState = {
  isLoggedIn: 'pending' as 'pending' | 'yes' | 'no',
  user: null,
  token: null as string | null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUser(state, action: PayloadAction<{ user: any }>) {
      state.user = action.payload.user;
      return state;
    },
    setLoginUser: (
      state,
      action: PayloadAction<{
        user: any;
        token: any;
      }>
    ) => {
      state.isLoggedIn = 'yes';
      state.user = action.payload.user;
      state.token = action.payload.token;
      return state;
    },
    clearLoginUser: () => {
      return initialState;
    },
  },
});

// getUser Thunk Action
export const fetchUserInfoThunk = () => {
  return async (dispatch: any, getState: any) => {
    const user = await API.getUser()
      .then((res) => res.data)
      .then((data) => data.data);

    if (user) {
      dispatch(setUser({ user }));
    }
    return user;
  };
};
// Action creators are generated for each case reducer function
export const { setLoginUser, clearLoginUser, setUser } = authSlice.actions;

export default persistReducer(
  {
    key: authSlice.name,
    storage,
    whitelist: ['user', 'token'],
  },
  authSlice.reducer
);
