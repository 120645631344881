import {
  Title,
  Text,
  TextInput,
  Button,
  Container,
  Group,
  Anchor,
  Center,
  Box,
  Modal,
  ModalProps,
  rem,
  PasswordInput,
  Loader,
  Stack,
} from '@mantine/core';
import { useForm, zodResolver } from '@mantine/form';
import { useInterval } from '@mantine/hooks';
import { IconArrowLeft, IconMail } from '@tabler/icons-react';
import { useMutation } from '@tanstack/react-query';
import { notifications } from 'notifications';
import { useEffect, useState } from 'react';
import { useLoginUser } from 'redux/selectors/useLoginUser';
import { API } from 'services';
import { z } from 'zod';

const otpSchema = z.object({
  otp: z.string(),
});
type OTPFormValue = z.infer<typeof otpSchema>;

const resetPasswordSchema = z
  .object({
    password: z
      .string()
      .nonempty({ message: 'Password is required' })
      .superRefine((data, ctx) => {
        const errors = [];
        if (!/[a-z]/.test(data)) errors.push('- Password must contain a lowercase letter.');
        if (!/[A-Z]/.test(data)) errors.push('- Password must contain an uppercase letter.');
        if (!/[0-9]/.test(data)) errors.push('- Password must contain a number.');
        if (!/[!@#$%^&*(),.?":{}|<>]/.test(data)) errors.push('- Password must contain a symbol.');
        if (data.length < 8) errors.push('- Password must be at least 8 characters long.');
        if (errors.length === 0) {
          return true;
        } else {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: errors.join('\n'),
          });
        }
      }),
    password_confirmation: z.string().nonempty({ message: 'Password confirmation is required' }),
  })
  .refine((data) => data.password === data.password_confirmation, {
    message: "Passwords don't match",
    path: ['password_confirmation'], // path of error
  });

type ResetPasswordFormValue = z.infer<typeof resetPasswordSchema>;

export function ResetPasswordModal(props: ModalProps) {
  const { loginUser } = useLoginUser();
  const userEmail = loginUser.trainer_email;

  const handleForgetPassword = useMutation(
    () =>
      API.forgetpassword({
        email: userEmail,
      }),
    {
      onSuccess: () => {
        setSeconds(20);
        setTimeout(() => {
          interval.start();
        }, 1000);
      },
    }
  );

  const handleResendOTP = useMutation(
    () => {
      return API.forgetpassword({ email: userEmail });
    },
    {
      onSuccess: () => {
        setSeconds(20);
        setTimeout(() => {
          interval.start();
        }, 1000);
      },
    }
  );

  const handleVerifyOTP = useMutation(API.verifyPasswordOTP);
  const otpForm = useForm<OTPFormValue>({
    validate: zodResolver(otpSchema),
  });

  const [seconds, setSeconds] = useState(0);
  const interval = useInterval(() => {
    setSeconds((s) => {
      if (s > 0) {
        return --s;
      } else {
        interval.stop();
        return s;
      }
    });
  }, 1000);

  const handleResetPassword = useMutation(API.resetPassword, {
    onSuccess: () => {
      notifications.success({
        message: 'Reset password successfully. Sign in with your new password',
      });
      props.onClose();
      handleForgetPassword.reset();
      handleVerifyOTP.reset();
      handleResetPassword.reset();
    },
  });

  const resetPasswordForm = useForm<ResetPasswordFormValue>({
    validate: zodResolver(resetPasswordSchema),
    validateInputOnChange: true,
  });

  useEffect(() => {
    if (props.opened) {
      handleForgetPassword.mutateAsync();
    } else {
      handleForgetPassword.reset();
      handleVerifyOTP.reset();
      handleResetPassword.reset();
    }
  }, [props.opened]);

  return (
    <Modal {...props} size={'xl'} closeOnClickOutside={false} keepMounted={false}>
      <Center>
        {handleForgetPassword.isLoading ? (
          <Center p="xl">
            <Stack align="center" justify="center">
              <Group>
                <IconMail></IconMail>
                <Loader />
              </Group>
              <Text>Sending One Time Pass to your email to verify your identity </Text>
            </Stack>
          </Center>
        ) : (
          handleForgetPassword.isSuccess &&
          !handleVerifyOTP.isSuccess && (
            <Container size={460} my={30}>
              <Title size={'md'} ta="center">
                One Time Pass (OTP) Verification
              </Title>
              <Text ta="center" mt="lg" size={'sm'} color="dimmed">
                Check your email and enter the OTP that sent to <Text underline>{userEmail}</Text>
              </Text>

              <Box mt={50}>
                <form
                  onSubmit={otpForm.onSubmit((values) => {
                    handleVerifyOTP.mutateAsync({
                      otp: values.otp,
                      email: userEmail,
                    });
                  })}
                >
                  <TextInput
                    sx={() => ({
                      '& input': {
                        textAlign: 'center',
                      },
                    })}
                    size="lg"
                    placeholder="Enter One Time Pass"
                    required
                    {...otpForm.getInputProps('otp')}
                  />

                  <Group align="center" position="center" mt="sm">
                    <Text color="dimmed" size={'xs'}>
                      Do not receive OTP Code? {seconds ? seconds : ''}
                    </Text>

                    {seconds <= 0 && (
                      <Button
                        size="xs"
                        loading={handleResendOTP.isLoading}
                        variant="outline"
                        onClick={() => {
                          handleResendOTP.mutate();
                        }}
                      >
                        Resend OTP
                      </Button>
                    )}
                  </Group>

                  <Group position="apart" mt="lg">
                    <Anchor c="dimmed" size="sm">
                      <Center inline>
                        <IconArrowLeft style={{ width: rem(12), height: rem(12) }} stroke={1.5} />
                        <Box
                          ml={5}
                          onClick={() => {
                            props.onClose();
                          }}
                        >
                          cancel
                        </Box>
                      </Center>
                    </Anchor>
                    <Button type="submit" loading={handleVerifyOTP.isLoading}>
                      Verify
                    </Button>
                  </Group>
                </form>
              </Box>
            </Container>
          )
        )}

        {handleVerifyOTP.isSuccess && (
          <Container w="100%" my={30}>
            <Title size={'md'} ta="center">
              Update Password
            </Title>

            <Box mt={50}>
              <form
                onSubmit={resetPasswordForm.onSubmit((values) =>
                  handleResetPassword.mutateAsync({
                    otp: handleVerifyOTP.variables!.otp,
                    email: handleVerifyOTP.variables!.email,
                    password: values.password,
                    password_confirmation: values.password_confirmation,
                  })
                )}
              >
                <PasswordInput
                  label="New Password"
                  style={{ whiteSpace: 'normal' }}
                  {...resetPasswordForm.getInputProps('password')}
                  sx={() => ({
                    '.mantine-InputWrapper-error': {
                      whiteSpace: 'break-spaces',
                    },
                  })}
                />

                <PasswordInput
                  label="Confirm Password"
                  {...resetPasswordForm.getInputProps('password_confirmation')}
                />

                <Group position="apart" mt="lg">
                  <Button w="100%" loading={handleResetPassword.isLoading} type="submit">
                    Reset Password
                  </Button>
                </Group>
              </form>
            </Box>
          </Container>
        )}
      </Center>
    </Modal>
  );
}
