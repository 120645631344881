import React from 'react';
import { Button, ButtonProps } from '@mantine/core';

// @ts-expect-error expected type error
interface GradientButtonProps extends ButtonProps, React.ComponentPropsWithoutRef<'button'> {}

export const GradientButton = (props: GradientButtonProps) => {
  return (
    <Button variant="gradient" gradient={{ from: 'teal', to: 'blue', deg: 60 }} {...props}></Button>
  );
};
