import {
  Paper,
  Title,
  Text,
  TextInput,
  Button,
  Container,
  Group,
  Anchor,
  Center,
  Box,
  Modal,
  ModalProps,
  rem,
  PasswordInput,
} from '@mantine/core';
import { useForm, zodResolver } from '@mantine/form';
import { useInterval } from '@mantine/hooks';
import { modals } from '@mantine/modals';
import { IconArrowLeft } from '@tabler/icons-react';
import { useMutation } from '@tanstack/react-query';
import { notifications } from 'notifications';
import { useEffect, useState } from 'react';
import { API } from 'services';
import { set, z } from 'zod';

const schema = z.object({
  email: z
    .string()
    .nonempty({
      message: 'Email is required',
    })
    .email({ message: 'Invalid email' }),
});
type FormValue = z.infer<typeof schema>;

const otpSchema = z.object({
  otp: z.string(),
});
type OTPFormValue = z.infer<typeof otpSchema>;

const resetPasswordSchema = z
  .object({
    password: z
      .string()
      .nonempty({ message: 'Password is required' })
      .superRefine((data, ctx) => {
        const errors = [];
        if (!/[a-z]/.test(data)) errors.push('- Password must contain a lowercase letter.');
        if (!/[A-Z]/.test(data)) errors.push('- Password must contain an uppercase letter.');
        if (!/[0-9]/.test(data)) errors.push('- Password must contain a number.');
        if (!/[!@#$%^&*(),.?":{}|<>]/.test(data)) errors.push('- Password must contain a symbol.');
        if (data.length < 8) errors.push('- Password must be at least 8 characters long.');
        if (errors.length === 0) {
          return true;
        } else {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: errors.join('\n'),
          });
        }
      }),
    password_confirmation: z.string().nonempty({ message: 'Password confirmation is required' }),
  })
  .refine((data) => data.password === data.password_confirmation, {
    message: "Passwords don't match",
    path: ['password_confirmation'], // path of error
  });

type ResetPasswordFormValue = z.infer<typeof resetPasswordSchema>;

export function ForgotPassword(props: ModalProps) {
  // FINISH RESET PASSWORD
  // TODO: CONTINUE HERE
  const handleForgetPassword = useMutation(API.forgetpassword, {
    onSuccess: () => {
      setSeconds(20);
      setTimeout(() => {
        interval.start();
      }, 1000);
    },
  });
  const form = useForm<FormValue>({
    validate: zodResolver(schema),
  });

  const handleResendOTP = useMutation(
    () => {
      if (!handleForgetPassword.isSuccess) {
        throw new Error('Unable to resend');
      }
      return API.forgetpassword({ email: handleForgetPassword.variables!.email });
    },
    {
      onSuccess: () => {
        setSeconds(20);
        setTimeout(() => {
          interval.start();
        }, 1000);
      },
    }
  );
  const handleVerifyOTP = useMutation(API.verifyPasswordOTP);
  const otpForm = useForm<OTPFormValue>({
    validate: zodResolver(otpSchema),
  });

  const [seconds, setSeconds] = useState(0);
  const interval = useInterval(() => {
    setSeconds((s) => {
      if (s > 0) {
        return --s;
      } else {
        interval.stop();
        return s;
      }
    });
  }, 1000);

  const handleResetPassword = useMutation(API.resetPassword, {
    onSuccess: () => {
      notifications.success({
        message: 'Reset password successfully. Sign in with your new password',
      });
      props.onClose();
      handleForgetPassword.reset();
      handleVerifyOTP.reset();
      handleResetPassword.reset();
    },
  });

  const resetPasswordForm = useForm<ResetPasswordFormValue>({
    validate: zodResolver(resetPasswordSchema),
    validateInputOnChange: true,
  });

  return (
    <Modal {...props} size={'xl'} closeOnClickOutside={false} keepMounted={false}>
      <Center>
        {!handleForgetPassword.isSuccess && (
          <Container size={460} my={30}>
            <Title size={'md'} ta="center">
              Forgot your password?
            </Title>
            <Text ta="center" mt="lg" size={'sm'} color="dimmed">
              Enter your email below, we will send you verification code. Before reset your password
            </Text>
            <Box mt={50}>
              <form onSubmit={form.onSubmit((values) => handleForgetPassword.mutateAsync(values))}>
                <TextInput
                  {...form.getInputProps('email')}
                  size="lg"
                  label="Your email"
                  placeholder="Your email"
                  required
                />
                <Group position="apart" mt="lg">
                  <Anchor c="dimmed" size="sm">
                    <Center inline>
                      <IconArrowLeft style={{ width: rem(12), height: rem(12) }} stroke={1.5} />
                      <Box
                        onClick={() => {
                          props.onClose();
                        }}
                        ml={5}
                      >
                        Back to the login page
                      </Box>
                    </Center>
                  </Anchor>
                  <Button type="submit" loading={handleForgetPassword.isLoading}>
                    Forget password
                  </Button>
                </Group>
              </form>
            </Box>
          </Container>
        )}

        {handleForgetPassword.isSuccess && !handleVerifyOTP.isSuccess && (
          <Container size={460} my={30}>
            <Title size={'md'} ta="center">
              One Time Pass (OTP) Verification
            </Title>
            <Text ta="center" mt="lg" size={'sm'} color="dimmed">
              Check your email and enter the OTP that sent to{' '}
              <Text underline>{handleForgetPassword.variables?.email}</Text>
            </Text>

            <Box mt={50}>
              <form
                onSubmit={otpForm.onSubmit((values) => {
                  handleVerifyOTP.mutateAsync({
                    otp: values.otp,
                    email: handleForgetPassword.variables!.email,
                  });
                })}
              >
                <TextInput
                  sx={() => ({
                    '& input': {
                      textAlign: 'center',
                    },
                  })}
                  size="lg"
                  placeholder="Enter One Time Pass"
                  required
                  {...otpForm.getInputProps('otp')}
                />

                <Group align="center" position="center" mt="sm">
                  <Text color="dimmed" size={'xs'}>
                    Do not receive OTP Code? {seconds ? seconds : ''}
                  </Text>

                  {seconds <= 0 && (
                    <Button
                      size="xs"
                      loading={handleResendOTP.isLoading}
                      variant="outline"
                      onClick={() => {
                        handleResendOTP.mutate();
                      }}
                    >
                      Resend OTP
                    </Button>
                  )}
                </Group>

                <Group position="apart" mt="lg">
                  <Anchor c="dimmed" size="sm">
                    <Center inline>
                      <IconArrowLeft style={{ width: rem(12), height: rem(12) }} stroke={1.5} />
                      <Box
                        ml={5}
                        onClick={() => {
                          handleForgetPassword.reset();
                        }}
                      >
                        Back
                      </Box>
                    </Center>
                  </Anchor>
                  <Button type="submit" loading={handleVerifyOTP.isLoading}>
                    Verify
                  </Button>
                </Group>
              </form>
            </Box>
          </Container>
        )}

        {handleVerifyOTP.isSuccess && (
          <Container w="100%" my={30}>
            <Title size={'md'} ta="center">
              Update Password
            </Title>

            <Box mt={50}>
              <form
                onSubmit={resetPasswordForm.onSubmit((values) =>
                  handleResetPassword.mutateAsync({
                    otp: handleVerifyOTP.variables!.otp,
                    email: handleVerifyOTP.variables!.email,
                    password: values.password,
                    password_confirmation: values.password_confirmation,
                  })
                )}
              >
                <PasswordInput
                  label="New Password"
                  style={{ whiteSpace: 'normal' }}
                  {...resetPasswordForm.getInputProps('password')}
                  sx={() => ({
                    '.mantine-InputWrapper-error': {
                      whiteSpace: 'break-spaces',
                    },
                  })}
                />

                <PasswordInput
                  label="Confirm Password"
                  {...resetPasswordForm.getInputProps('password_confirmation')}
                />

                <Group position="apart" mt="lg">
                  <Button w="100%" loading={handleResetPassword.isLoading} type="submit">
                    Reset Password
                  </Button>
                </Group>
              </form>
            </Box>
          </Container>
        )}
      </Center>
    </Modal>
  );
}
