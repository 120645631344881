import {
  Box,
  Button,
  SimpleGrid,
  Stack,
  Title,
  Text,
  Flex,
  Select,
  Image,
  Tabs,
} from '@mantine/core';
// import { DatePicker } from '@mantine/dates';
import DatePicker from 'react-datepicker';
import { ReactComponent as LearnerStatSvg } from 'assets/dashboard/icon_learner.svg';
import { ReactComponent as RatingSvg } from 'assets/dashboard/icon_traqom.svg';
import styles from './../DashboardPage/DashboarPage.module.scss';
import { useQuery } from '@tanstack/react-query';
import { API } from 'services';
import { useEffect, useState } from 'react';
import { useLoginUser } from 'redux/selectors/useLoginUser';
import ppImage from 'assets/inner/pp.png';
import medal_1 from 'assets/report/medal_1.png';
import medal_2 from 'assets/report/medal_2.png';
import medal_3 from 'assets/report/medal_3.png';
import loading from 'assets/report/loading.gif';
import Slider from 'react-slick';

import { ReactComponent as Clap } from 'assets/report/clap.svg';
import { ReactComponent as Icon_A } from 'assets/report/icon_A.svg';
import { ReactComponent as Icon_b } from 'assets/report/icon_b.svg';
import { ReactComponent as Icon_c } from 'assets/report/icon_c.svg';
import { ReactComponent as Pop } from 'assets/report/pop.svg';
import { ReactComponent as Try } from 'assets/report/try.svg';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';

export function ReportPage() {
  const { loginUser } = useLoginUser();
  const [isLoading, setLoading] = useState(true);

  const filter_date = new Date();
  const year = filter_date.getFullYear();
  const month = filter_date.getMonth();
  const thisMonthFirstDay = new Date(year, month, 1);
  const currentStartDayOfMonth = new Date(
    thisMonthFirstDay.getFullYear(),
    thisMonthFirstDay.getMonth(),
    1
  );
  const nextMonthFirstDay = new Date(year, month + 1, 1);
  const currentEndDayOfMonth = new Date(
    nextMonthFirstDay.getFullYear(),
    nextMonthFirstDay.getMonth(),
    0
  );

  const [filter_date_start, setFilterDateStart] = useState(currentStartDayOfMonth);
  const [filter_date_end, setFilterDateEnd] = useState(currentEndDayOfMonth);
  const [filter_trainer, setFilterTrainer] = useState(
    loginUser &&
      loginUser.is_corporate == 1 &&
      loginUser.trainer_members &&
      loginUser.trainer_members.length > 0
      ? `${loginUser.trainer_members[0].trainer_id}`
      : loginUser.trainer_id
  );

  const handlePrint = () => {
    window.print();
  };

  useEffect(() => {
    // prevent horizontal scroll back and forward navigation
    // because our datatable require horizontal scroll alots
    const cached = document.body.style.overscrollBehaviorX;
    document.body.style.overscrollBehaviorX = 'none';
    return () => {
      document.body.style.overscrollBehaviorX = cached;
    };
  }, []);

  const [activeTab, setActiveTab] = useState(
    loginUser &&
      loginUser.is_corporate == 1 &&
      loginUser.trainer_members &&
      loginUser.trainer_members.length > 0
      ? `${loginUser.trainer_members[0].trainer_id}`
      : null
  ); // Default to first tab

  useEffect(() => {
    setFilterTrainer(activeTab);
  }, [activeTab]);

  const report = useQuery(['report', filter_date_start, filter_date_end, filter_trainer], () => {
    setLoading(true); // Set loading state to true before making the API request

    return API.getReportData({
      filter_date_start: moment(filter_date_start).format('DD-MM-Y'),
      filter_date_end: moment(filter_date_end).format('DD-MM-Y'),
      filter_trainer: filter_trainer, // Will update when activeTab changes
    })
      .then((res) => {
        setLoading(false); // Set loading state to false when the request is complete
        return res.data;
      })
      .catch((error) => {
        setLoading(false); // Make sure to set loading state to false in case of an error
        throw error;
      });
  });

  const slider_settings = {
    // dots: true,
    dots: true,
    prevArrow: <button style={{ display: 'none' }}></button>, // Hide previous button
    nextArrow: <button style={{ display: 'none' }}></button>, // Hide next button
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 5000,
  };

  const [selectedOption, setSelectedOption] = useState('Highest Rated Top 3 Trainers');
  const handleSelectChange = (e: any) => {
    setSelectedOption(e);
  };

  return (
    <Stack px={{ base: 0, md: 30 }}>
      {isLoading && (
        <Box className={'loading_box'}>
          <Image src={loading} width="100"></Image>
        </Box>
      )}
      <Tabs value={activeTab} onTabChange={setActiveTab}>
        {loginUser.is_corporate == 1 && (
          <Tabs.List>
            {loginUser?.trainer_members?.map((trainer: any, index: any) => (
              <Tabs.Tab key={`${trainer.trainer_id}`} value={`${trainer.trainer_id}`}>
                {trainer.trainer_name}
              </Tabs.Tab>
            ))}
          </Tabs.List>
        )}

        <div style={{ marginTop: '20px' }}>
          <Flex className={'flex_wrap'}>
            <Box className={'w100 md_w100'}>
              <Box bg="#FFF" p={20}>
                <Title order={2}>Monthly Report Card</Title>
                <Box
                  my={10}
                  bg="#F8F7FE"
                  sx={(theme) => ({
                    borderRadius: theme.radius.md,
                  })}
                  style={{ overflow: 'hidden' }}
                >
                  <SimpleGrid h="100%" cols={1} breakpoints={[{ maxWidth: 'md', cols: 1 }]}>
                    <Flex h="100%" px={{ base: 15, md: 30 }} className=" flex_direction_column">
                      {/* <Box pos={'absolute'} top={0} left={0} className={styles.stripe}>
                        <RibbonSvg />
                      </Box> */}

                      <Flex py={20}>
                        {report.data &&
                        report.data.trainer_user &&
                        report.data.trainer_user.trainer_image_url ? (
                          <Image
                            src={report.data.trainer_user.trainer_image_url}
                            width="80"
                            className="profile_image sm_block_image"
                          ></Image>
                        ) : (
                          <Image
                            src={ppImage}
                            width="80"
                            className="profile_image sm_block_image"
                          ></Image>
                        )}
                        <Box px={{ base: 0, sm: 20 }}>
                          <Title order={2}>
                            <Text display={'inline'} color="#8c4799" ml={4}>
                              {report.data && report.data.trainer_user.trainer_name}
                            </Text>
                          </Title>
                          <Text size={'lg'}>
                            The art of teaching is the art of assisting discovery.
                          </Text>
                        </Box>
                      </Flex>
                      <Flex my={20} className="rating">
                        <Flex
                          px={{
                            base: 0,
                            sm: 20,
                          }}
                          className="rating_box center_line flex_wrap flex_direction_row"
                        >
                          <Flex style={{ width: '100%' }} my={20}>
                            <Box style={{ width: 'calc(100% - 110px)' }}>
                              <Text fw={700} size={'lg'} color="#8c4799">
                                {report.data && report.data.trainer_user.traqom_rating} /{' '}
                                <Text display={'inline'} fw={500} color="#8c4799">
                                  5
                                </Text>
                              </Text>
                              <Text fw={500}>TRAQOM Rating</Text>
                            </Box>
                            <Flex style={{ width: '110px' }} className={'flex_justify_center'}>
                              <RatingSvg className="" style={{ width: '60px' }} />
                            </Flex>
                          </Flex>

                          <Flex style={{ width: '100%' }} my={5}>
                            <Text fw={500} style={{ width: 'calc(100% - 110px)' }}>
                              Latest Class Update
                            </Text>
                            <Text
                              fw={700}
                              style={{ width: '110px' }}
                              align="center"
                              color="#8c4799"
                            >
                              {report.data && report.data.trainer_user.traqom_latest}
                            </Text>
                          </Flex>

                          <Flex style={{ width: '100%' }} my={5}>
                            <Text fw={500} style={{ width: 'calc(100% - 110px)' }}>
                              Total Classes
                            </Text>
                            <Text
                              fw={700}
                              style={{ width: '110px' }}
                              align="center"
                              color="#8c4799"
                            >
                              {report.data && report.data.trainer_user.total_classes}
                            </Text>
                          </Flex>

                          <Flex style={{ width: '100%' }} my={5}>
                            <Text fw={500} style={{ width: 'calc(100% - 110px)' }}>
                              Classes Not Updated
                            </Text>
                            <Text
                              fw={700}
                              style={{ width: '110px' }}
                              align="center"
                              color="#8c4799"
                            >
                              {report.data && report.data.trainer_user.total_classes_not_update}
                            </Text>
                          </Flex>
                        </Flex>

                        <Flex
                          px={{
                            base: 0,
                            sm: 20,
                          }}
                          className="rating_box flex_wrap flex_direction_row"
                        >
                          <Flex style={{ width: '100%' }} my={20}>
                            <Box style={{ width: 'calc(100% - 110px)' }}>
                              <Text fw={700} size={'lg'} color="#8c4799">
                                {report.data && report.data.trainer_user.le_rating} /{' '}
                                <Text display={'inline'} fw={500} color="#8c4799">
                                  6
                                </Text>
                              </Text>
                              <Text fw={500}>{"Learner's Evaluation"}</Text>
                            </Box>
                            <Flex style={{ width: '110px' }} className={'flex_justify_center'}>
                              <LearnerStatSvg className="" style={{ width: '60px' }} />
                            </Flex>
                          </Flex>

                          <Flex style={{ width: '100%' }} my={5}>
                            <Text fw={500} style={{ width: 'calc(100% - 110px)' }}>
                              Latest Class Update
                            </Text>
                            <Text
                              fw={700}
                              style={{ width: '110px' }}
                              align="center"
                              color="#8c4799"
                            >
                              {report.data && report.data.trainer_user.le_dated_update}
                            </Text>
                          </Flex>

                          <Flex style={{ width: '100%' }} my={5}>
                            <Text fw={500} style={{ width: 'calc(100% - 110px)' }}>
                              Total Classes
                            </Text>
                            <Text
                              fw={700}
                              style={{ width: '110px' }}
                              align="center"
                              color="#8c4799"
                            >
                              {report.data && report.data.trainer_user.le_total_classes}
                            </Text>
                          </Flex>

                          <Flex style={{ width: '100%' }} my={5}>
                            <Text fw={500} style={{ width: 'calc(100% - 110px)' }}>
                              Classes Not Updated
                            </Text>
                            <Text
                              fw={700}
                              style={{ width: '110px' }}
                              align="center"
                              color="#8c4799"
                            >
                              {report.data && report.data.trainer_user.le_total_classes_not_update}
                            </Text>
                          </Flex>
                        </Flex>
                      </Flex>
                      {/* <Box className={styles.strip_container}>
                        <Box className={styles.strip_background}>
                          <RibbonSvg className={styles.strip_image} />
                        </Box>
                      </Box> */}
                    </Flex>
                  </SimpleGrid>
                </Box>
              </Box>
            </Box>
            <Box px={10} className={'w30 md_w100 trainer_rating_box hide'}>
              <Box bg="#FFF" p={20}>
                <Select
                  label=""
                  placeholder="Rating"
                  data={['Highest Rated Top 3 Trainers', 'Lowest Rated 3 Trainers']}
                  defaultValue="Highest Rated Top 3 Trainers"
                  onChange={handleSelectChange}
                />
                {selectedOption === 'Highest Rated Top 3 Trainers' && (
                  <Box py={10}>
                    {report.data &&
                      report.data.highest_rating.map((item: any, index: any) => (
                        <Flex key={item.id} py={10} className={'flex_align_center'}>
                          <Flex className={'w70 flex_align_center'}>
                            {item.trainer_image_url ? (
                              <Image
                                src={item.trainer_image_url}
                                width="80"
                                className="profile_image sm_block_image"
                              />
                            ) : (
                              <Image
                                src={ppImage}
                                width="80"
                                className="profile_image sm_block_image"
                              />
                            )}
                            <Text fw={700} p={5}>
                              {item.trainer_name}
                            </Text>
                            {index === 0 && <Image src={medal_1} width="20" />}
                            {index === 1 && <Image src={medal_2} width="20" />}
                            {index === 2 && <Image src={medal_3} width="20" />}
                          </Flex>
                          <Text fw={700} p={5} className={'w30'} ta={'right'}>
                            {parseFloat(item.le_rating).toFixed(2)} / 6
                          </Text>
                        </Flex>
                      ))}
                  </Box>
                )}
                {selectedOption === 'Lowest Rated 3 Trainers' && (
                  <Box py={10}>
                    {report.data &&
                      report.data.lowerest_rating.map((item: any) => (
                        <Flex key={item.id} py={10} className={'flex_align_center'}>
                          <Flex className={'w70 flex_align_center'}>
                            {item.trainer_image_url ? (
                              <Image
                                src={item.trainer_image_url}
                                width="80"
                                className="profile_image sm_block_image"
                              ></Image>
                            ) : (
                              <Image
                                src={ppImage}
                                width="80"
                                className="profile_image sm_block_image"
                              ></Image>
                            )}
                            <Text fw={700} p={5}>
                              {item.trainer_name}
                            </Text>
                          </Flex>
                          <Text fw={700} p={5} className={'w30'} ta={'right'}>
                            {parseFloat(item.le_rating).toFixed(2)} / 6
                          </Text>
                        </Flex>
                      ))}
                  </Box>
                )}
              </Box>
            </Box>
          </Flex>

          <Box bg="#FFF" p={20}>
            <Box className="report_date">
              <Title order={4} py={10}>
                Report Date
              </Title>
              <Flex>
                {loginUser.trainer_id == '43' && (
                  <Box>
                    <Text>Trainer</Text>
                    <Select
                      label=""
                      placeholder="Select One"
                      onChange={(data: any) => setFilterTrainer(data)}
                      data={loginUser.trainers.map((trainer: any) => ({
                        value: trainer.trainer_id.toString(),
                        label: trainer.trainer_name,
                      }))}
                    />
                  </Box>
                )}
                <Box mx={10}>
                  <Text>Start Date</Text>
                  <DatePicker
                    selected={filter_date_start}
                    onChange={(date: any) => setFilterDateStart(date)}
                    dateFormat="dd-MM-yyyy"
                  />
                </Box>
                <Box>
                  <Text>End Date</Text>
                  <DatePicker
                    selected={filter_date_end}
                    onChange={(date: any) => setFilterDateEnd(date)}
                    dateFormat="dd-MM-yyyy"
                  />
                </Box>
                <Box>
                  <Button my={25} onClick={handlePrint}>
                    Print
                  </Button>
                </Box>
              </Flex>
            </Box>

            <Box my={30} style={{ overflowX: 'auto', paddingBottom: '20px' }}>
              <Title order={4} py={10}>
                Overview
              </Title>
              <table className="reportTable">
                <thead>
                  <tr>
                    <th style={{ background: '#f2f4fd' }} colSpan={3}>
                      Current Month
                    </th>
                    <th style={{ background: '#f5fdfc' }} colSpan={3}>
                      Year Till Date
                    </th>
                  </tr>
                  <tr>
                    <th>Trainer Rating</th>
                    <th>No. of Courses Taught</th>
                    <th>No. of Learners Taught</th>
                    <th>Trainer Rating</th>
                    <th>No. of Courses Taught</th>
                    <th>No. of Learners Taught</th>
                  </tr>
                </thead>
                <tbody>
                  {report.data && (
                    <tr>
                      <td>
                        {report.data.month_overview.total_ave > 4 && (
                          <div className="icon-container">
                            <Icon_A className="icon" style={{ width: '40px' }} />
                            {report.data.month_overview.total_ave + ' / 6'}
                            <div className="bottom-box green-bottom-box">
                              You Are Exceptional! <Pop className="icon" style={{ width: '40px' }} />
                            </div>
                          </div>
                        )}
                        {report.data.month_overview.total_ave <= 4 &&
                          report.data.month_overview.total_ave > 3 && (
                            <div className="icon-container">
                              <Icon_b className="icon" style={{ width: '40px' }} />
                              {report.data.month_overview.total_ave + ' / 6'}
                              <div className="bottom-box orange-bottom-box">
                                You Are Amazing! <Clap className="icon" style={{ width: '40px' }} />
                              </div>
                            </div>
                          )}
                        {report.data.month_overview.total_ave <= 3 && (
                          <div className="icon-container">
                            <Icon_c className="icon" style={{ width: '40px' }} />
                            {report.data.month_overview.total_ave + ' / 6'}
                            <div className="bottom-box red-bottom-box">
                              Try Harder! <Try className="icon" style={{ width: '40px' }} />
                            </div>
                          </div>
                        )}
                      </td>
                      <td>{report.data.month_overview.no_of_course}</td>
                      <td>{report.data.month_overview.no_of_learner}</td>

                      <td>
                        {report.data.year_overview.total_ave > 4 && (
                          <div className="icon-container">
                            <Icon_A className="icon" style={{ width: '40px' }} />
                            {report.data.year_overview.total_ave + ' / 6'}
                            <div className="bottom-box green-bottom-box">
                              You Are Exceptional! <Pop className="icon" style={{ width: '40px' }} />
                            </div>
                          </div>
                        )}
                        {report.data.year_overview.total_ave <= 4 &&
                          report.data.year_overview.total_ave > 3 && (
                            <div className="icon-container">
                              <Icon_b className="icon" style={{ width: '40px' }} />
                              {report.data.year_overview.total_ave + ' / 6'}
                              <div className="bottom-box orange-bottom-box">
                                You Are Amazing! <Clap className="icon" style={{ width: '40px' }} />
                              </div>
                            </div>
                          )}
                        {report.data.year_overview.total_ave <= 3 && (
                          <div className="icon-container">
                            <Icon_c className="icon" style={{ width: '40px' }} />
                            {report.data.year_overview.total_ave + ' / 6'}
                            <div className="bottom-box red-bottom-box">
                              Try Harder! <Try className="icon" style={{ width: '40px' }} />
                            </div>
                          </div>
                        )}
                      </td>
                      <td>{report.data.year_overview.no_of_course}</td>
                      <td>{report.data.year_overview.no_of_learner}</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </Box>

            <Box my={30} style={{ overflowX: 'auto' }}>
              <Title order={4} py={10}>
                Learner Rating (Month)
              </Title>
              <table className="reportTable">
                <thead>
                  <tr>
                    <th style={{ background: '#f2f4fd' }}>Course Date</th>
                    <th style={{ background: '#f2f4fd' }}>Course</th>
                    <th style={{ background: '#f2f4fd' }}>No. of Learners</th>
                    <th style={{ background: '#f5fdfc' }}>Q1 Knowledge</th>
                    <th style={{ background: '#f5fdfc' }}>Q2 Studies</th>
                    <th style={{ background: '#f5fdfc' }}>Q3 Delivery</th>
                    <th style={{ background: '#f5fdfc' }}>Q4 Engagement</th>
                    <th style={{ background: '#fefaf3' }}>Average Rating</th>
                    <th style={{ background: '#fefaf3' }}>TRAQOM</th>
                  </tr>
                </thead>
                <tbody>
                  {report.data &&
                    report.data.learner_rating_month.map((item: any) => (
                      <tr key={item.id}>
                        <td>{item.courserun_course_from}</td>
                        <td>{item.course_title}</td>
                        <td>{item.no_of_learner}</td>
                        <td>
                          {item.ave_rating_knowledgeable > 0
                            ? parseFloat(item.ave_rating_knowledgeable).toFixed(2)
                            : '0.00'}
                        </td>
                        <td>
                          {item.ave_rating_presented > 0
                            ? parseFloat(item.ave_rating_presented).toFixed(2)
                            : '0.00'}
                        </td>
                        <td>
                          {item.ave_rating_delivered > 0
                            ? parseFloat(item.ave_rating_delivered).toFixed(2)
                            : '0.00'}
                        </td>
                        <td>
                          {item.ave_rating_encouraged > 0
                            ? parseFloat(item.ave_rating_encouraged).toFixed(2)
                            : '0.00'}
                        </td>
                        <td>
                          {item.ave_rating > 0 ? parseFloat(item.ave_rating).toFixed(2) : '0.00'}
                        </td>
                        <td>{parseFloat(item.courserun_traqom_rating).toFixed(2)}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </Box>

        
            <Box my={30}>
              <Title order={4} py={10}>Notable Feedback From Learner Evaluation (Month)</Title>
              <Flex className="flex_wrap flex_direction_row">
                <Flex className="w50 sm_w100 flex_direction_column" style={{'border':'1px solid #cbcbcb'}}>
                  <Box bg={'#f5fdfc'} p={10} style={{'borderBottom':'1px solid #cbcbcb'}}>
                    <Title order={5} style={{'textAlign':'center'}}>Positive</Title>
                  </Box>
                  <Box style={{'overflow': 'hidden'}}>
                    {/* { report.data && report.data.top_3.length > 1 ? (
                      <Slider className={styles.dk_image} {...slider_settings}>
                        {report.data && report.data.top_3.map((item:any) => (
                            <Box p={10} key={item.id}>
                              <Text>{item.answer}</Text>
                              <Text size="xs" ta="right">
                                {item.date}
                              </Text>
                            </Box>
                        ))}
                      </Slider>
                    ) : (
                      report.data && report.data.top_3.map((item:any) => (
                        <Box p={10} key={item.id}>
                          <Text>{item.answer}</Text>
                          <Text size="xs" ta="right">{item.date}</Text>
                        </Box>
                      ))
                    )} */}
                    { report.data && report.data.top_3.map((item:any) => (
                        <Box p={10} key={item.id}>
                          <Text>{item.answer}</Text>
                          <Text size="xs" ta="right">{item.date}</Text>
                        </Box>
                      ))
                    }
                  </Box>
                </Flex>
                <Flex className="w50 sm_w100 flex_direction_column" style={{'border':'1px solid #cbcbcb'}}>
                  <Box bg={'#fefaf3'} p={10} style={{'borderBottom':'1px solid #cbcbcb'}}>
                    <Title order={5} style={{'textAlign':'center'}}>Negative</Title>
                  </Box>
                  <Box style={{'overflow': 'hidden'}}>
                    {/* { report.data && report.data.lower_3.length > 1 ? (
                      <Slider className={styles.dk_image} {...slider_settings}>
                        {report.data && report.data.lower_3.map((item:any) => (
                            <Box p={10} key={item.id}>
                              <Text>{item.answer}</Text>
                              <Text size="xs" ta="right">
                                {item.date}
                              </Text>
                            </Box>
                        ))}
                      </Slider>
                    ) : (
                      report.data && report.data.lower_3.map((item:any) => (
                        <Box p={10} key={item.id}>
                          <Text>{item.answer}</Text>
                          <Text size="xs" ta="right">{item.date}</Text>
                        </Box>
                      ))
                    )} */}
                    { report.data && report.data.lower_3.map((item:any) => (
                        <Box p={10} key={item.id}>
                          <Text>{item.answer}</Text>
                          <Text size="xs" ta="right">{item.date}</Text>
                        </Box>
                      ))
                    }
                  </Box>
                </Flex>
              </Flex>
            </Box>


            <Box my={30} className="">
              <Title order={4} py={10}>Trainer & Internal Feedback</Title>
              <Flex className="flex_wrap flex_direction_row">
                <Flex className="w50 sm_w100 flex_direction_column" style={{'border':'1px solid #cbcbcb'}}>
                  <Box bg={'#f2f4fd'} p={10} style={{'borderBottom':'1px solid #cbcbcb'}}>
                    <Title order={5} style={{'textAlign':'center'}}>Trainer Feedback</Title>
                  </Box>
                  <Box>
                    <Box p={10}>
                      {report.data && report.data.trainer_feedback.map((item:any) => (
                        <Box key={item.id}>
                          <Text fw={700}>{item.courserun_course_from} - {item.course_title}</Text>
                          <Text dangerouslySetInnerHTML={{ __html: item.display }}></Text>
                        </Box>
                      ))}
                    </Box>
                  </Box>
                </Flex>
                <Flex
                  className="w50 sm_w100 flex_direction_column"
                  style={{ border: '1px solid #cbcbcb' }}
                >
                  <Box bg={'#f5fdfc'} p={10} style={{ borderBottom: '1px solid #cbcbcb' }}>
                    <Title order={5} style={{ textAlign: 'center' }}>
                      Internal Feedback
                    </Title>
                  </Box>
                  <Box>
                    <Box p={10}>
                      {report.data &&
                        report.data.internal_feedback.map((item: any) => (
                          <Box key={item.id}>
                            <Text fw={700}>
                              {item.courserun_course_from} - {item.course_title}
                            </Text>
                            <Text dangerouslySetInnerHTML={{ __html: item.display }}></Text>
                          </Box>
                        ))}
                    </Box>
                  </Box>
                </Flex>
              </Flex>
            </Box>
          </Box>
        </div>
      </Tabs>
    </Stack>
  );
}
