import {
  Box,
  Center,
  SimpleGrid,
  TextInput,
  Title,
  Group,
  PasswordInput,
  BackgroundImage,
  Button,
} from '@mantine/core';
import { IconLock, IconUserCircle } from '@tabler/icons-react';
import { useNavigate } from 'react-router-dom';
import LoginImage from 'assets/Login/bg.jpg';
import WinImage1 from 'assets/Login/win01.png';
// import WinImage2 from 'assets/Login/win02.png';
import WinImage3 from 'assets/Login/win03b.png';
import { ReactComponent as FCALogo } from 'assets/Login/logo.svg';
// import TrainerImage from 'assets/Login/DK.png';
// import TrainerImage1 from 'assets/Login/pic01.jpg';
// import TrainerImage2 from 'assets/Login/pic02.jpg';

import TrainerImage1 from 'assets/Login/trainer/Daryl01.png';
import TrainerImage2 from 'assets/Login/trainer/David01.png';
import TrainerImage3 from 'assets/Login/trainer/Eugene01.png';
import TrainerImage4 from 'assets/Login/trainer/Gold01.png';
import TrainerImage5 from 'assets/Login/trainer/grace01.png';
import TrainerImage6 from 'assets/Login/trainer/Joshua01.png';
import TrainerImage7 from 'assets/Login/trainer/Paul01.png';
import TrainerImage8 from 'assets/Login/trainer/Qisti01.png';
import TrainerImage9 from 'assets/Login/trainer/Ronald01.png';
import TrainerImage10 from 'assets/Login/trainer/Shawn01.png';

import PassImage from 'assets/Login/pass.png';
import { useForm, zodResolver } from '@mantine/form';
import { useDispatch } from 'react-redux';
import { API } from 'services';
import { setLoginUser } from 'redux/reducers/auth';
import { useMutation } from '@tanstack/react-query';
import { getErrorMessage } from 'services/getErrorMessage';
import { notifications } from 'notifications';
import { z } from 'zod';
import { GradientButton } from 'components/GradientButton';
import { ForgotPassword } from './ForgetPasswordModal';
import { useDisclosure } from '@mantine/hooks';
import styles from './LoginPage.module.scss';
import { useEffect } from 'react';
import { useLoginUser } from 'redux/selectors/useLoginUser';
import Slider from 'react-slick';

const schema = z.object({
  email: z.string().email({ message: 'Invalid email' }),
  password: z.string().nonempty({ message: 'Password is required' }),
});

type FormValue = z.infer<typeof schema>;

export const LoginPage = () => {
  const { loginUser } = useLoginUser();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [forgetPasswordOpened, forgetPasswordModalHelper] = useDisclosure(false);
  const form = useForm({
    initialValues: import.meta.env.DEV
      ? {
          email: '',
          password: '',
        }
      : {
          email: '',
          password: '',
        },
    validate: zodResolver(schema),
  });

  const handleLogin = useMutation(
    (values: FormValue) => {
      return API.login({
        email: values.email,
        password: values.password,
      })
        .then((res) => {
          return res.data;
        })
        .then((res) => {
          dispatch(
            setLoginUser({
              token: res.data.token,
              user: res.data.user,
            })
          );
          if (res.data.user.is_corporate == 1) {
            navigate('/profile', { replace: true });
          } else {
            navigate('/dashboard', { replace: true });
          }
          return res.data;
        });
    },
    {
      onError: (error) => {
        notifications.error({
          title: 'Login Failed',
          message: getErrorMessage(error),
        });
      },
    }
  );

  useEffect(() => {
    if (loginUser) {
      if (loginUser.is_corporate == 1) {
        navigate('/profile', { replace: true });
      } else {
        navigate('/dashboard', { replace: true });
      }
    }
  }, []);

  // Move the image to the left
  // const moveLeft = () => {
  //   const img = document.querySelector('.winImage2');
  //   if (img) {
  //     img.style.transition = 'transform 1s ease';
  //     img.style.transform = 'translateX(0%)';
  //   }
  // };

  // // Toggle the image visibility
  // const toggleImageVisibility = () => {
  //   const img = document.querySelector('.winImage2') as any;
  //   if (img) {
  //     if (img.style.display === 'none') {
  //       img.style.display = 'block';
  //     } else {
  //       img.style.display = 'none';
  //     }
  //   }
  // };

  // // Run the animation loop
  // useEffect(() => {
  //   const animationLoop = setInterval(() => {
  //     // Move the image left and then toggle visibility after 1 second
  //     // moveLeft();
  //     setTimeout(toggleImageVisibility, 1000);
  //   }, 5000); // Repeat every 10 seconds

  //   // Clear the interval on component unmount
  //   return () => clearInterval(animationLoop);
  // }, []);

  const slider_settings = {
    dots: false,
    prevArrow: <button style={{ display: 'none' }}></button>, // Hide previous button
    nextArrow: <button style={{ display: 'none' }}></button>, // Hide next button
    infinite: true,
    fade: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
  };

  return (
    <SimpleGrid
      cols={2}
      w="100%"
      h="100vh"
      style={{ gap: 0 }}
      breakpoints={[
        {
          maxWidth: 'md',
          cols: 1,
        },
      ]}
    >
      <Box h={{ md: '100%' }} style={{ position: 'relative' }}>
        <BackgroundImage
          src={LoginImage}
          style={{
            backgroundPosition: 'right bottom',
            width: '100%',
            height: '100%',
            position: 'relative',
          }}
        >
          <Center w="100%" h={'100%'} style={{ overflow: 'hidden' }}>
            <img className={`${styles.win_image} winImage1`} src={WinImage3} alt="" />
            {/* <img className={`${styles.win_image} winImage2`} src={WinImage2} alt="" /> */}
            <div className={styles.main_figure}>
              <img className={styles.pass_image} src={PassImage} alt="" />
            </div>
            <div>
              <Slider className={styles.dk_image} {...slider_settings}>
                <div className={'trainer_box'}>
                  {/* <div className={styles.ribbon}> */}
                  <div className={'trainer_message'}>
                    <p>
                      If You Work Hard on Your Job, You Can Make a Living. If You Work Hard on
                      Yourself, You Can Make a Fortune.
                    </p>
                    <span>- Daryl Kang</span>
                  </div>
                  <img src={TrainerImage1} alt="" className={'trainer_img'} />
                </div>
                <div className={'trainer_box'}>
                  {/* <div className={styles.ribbon}> */}
                  <div className={'trainer_message'}>
                    <p>Nothing is impossible. The word itself says &apos;I&apos;m possible!</p>
                    <span>- David Aw</span>
                  </div>
                  <img src={TrainerImage2} alt="" className={'trainer_img'} />
                </div>
                <div className={'trainer_box'}>
                  {/* <div className={styles.ribbon}> */}
                  <div className={'trainer_message'}>
                    <p>
                      Keep on learning, keep on expanding your horizons, and your mind shall never
                      age!
                    </p>
                    <span>- Eugene Chow</span>
                  </div>
                  <img src={TrainerImage3} alt="" className={'trainer_img'} />
                </div>
                <div className={'trainer_box'}>
                  {/* <div className={styles.ribbon}> */}
                  <div className={'trainer_message'}>
                    <p>
                      Upskilling, Reskilling and Lifelong Learning are the keys that unlock doors to
                      new opportunities, broaden horizons, and promote personal and professional
                      development in an ever-changing world.
                    </p>
                    <span>- Gold Poovan</span>
                  </div>
                  <img src={TrainerImage4} alt="" className={'trainer_img'} />
                </div>
                <div className={'trainer_box'}>
                  {/* <div className={styles.ribbon}> */}
                  <div className={'trainer_message'}>
                    <p>
                      The more I learn, the more I realize how much I don’t know”. - Albert
                      Einstein. Never stop learning! 😎
                    </p>
                    <span>- Grace Koh</span>
                  </div>
                  <img src={TrainerImage5} alt="" className={'trainer_img'} />
                </div>
                <div className={'trainer_box'}>
                  {/* <div className={styles.ribbon}> */}
                  <div className={'trainer_message'}>
                    <p>The Best Way to Predict Your Future is to Create it.</p>
                    <span>- Joshua Judah</span>
                  </div>
                  <img src={TrainerImage6} alt="" className={'trainer_img'} />
                </div>
                <div className={'trainer_box'}>
                  {/* <div className={styles.ribbon}> */}
                  <div className={'trainer_message'}>
                    <p>Invest in Upskilling for Future Opportunities</p>
                    <span>- Paul Lim</span>
                  </div>
                  <img src={TrainerImage7} alt="" className={'trainer_img'} />
                </div>
                <div className={'trainer_box'}>
                  {/* <div className={styles.ribbon}> */}
                  <div className={'trainer_message'}>
                    <p>
                      Lifelong learning is the secret to eternal youth; never stop finding joy in
                      the journey of knowledge and growth. For in the heart of all fun, lies the
                      essence of endless discovery.
                    </p>
                    <span>- Qisti Razan Putra</span>
                  </div>
                  <img src={TrainerImage8} alt="" className={'trainer_img'} />
                </div>
                <div className={'trainer_box'}>
                  {/* <div className={styles.ribbon}> */}
                  <div className={'trainer_message'}>
                    <p>
                      We must be the change we want to see in our society. Every Singaporean must
                      want to give themselves – and their children – more breathing space to
                      discover and develop their diverse talents, and to maximise their potential.
                    </p>
                    <span>- Ronald Chua</span>
                  </div>
                  <img src={TrainerImage9} alt="" className={'trainer_img'} />
                </div>
                <div className={'trainer_box'}>
                  {/* <div className={styles.ribbon}> */}
                  <div className={'trainer_message'}>
                    <p>Never stop learning, because life never stops teaching.</p>
                    <span>- Shawn Yeo</span>
                  </div>
                  <img src={TrainerImage10} alt="" className={'trainer_img'} />
                </div>
              </Slider>
            </div>
          </Center>
          <div className={styles.fca_logo}>
            <FCALogo width={'100%'} height={'auto'} />
          </div>
        </BackgroundImage>
      </Box>
      <Box bg="#F5FAFE" className="p-1">
        <Center style={{ flexDirection: 'column' }} h="100%">
          <Title color={'rgb(15, 24, 32)'} className="font-coolvetica text-center" weight={600}>
            Your Path to Success Starts Here
          </Title>
          <Title className="font-lobster with-stroke">Trainer&apos;s Portal</Title>
          <p className={`font-poppins ${styles.signInText}`}>Sign In</p>
          <form
            onSubmit={form.onSubmit((values) => handleLogin.mutateAsync(values))}
            style={{
              marginTop: 10,
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
              gap: 10,
            }}
          >
            <TextInput
              {...form.getInputProps('email')}
              size="lg"
              w={'80%'}
              maw={400}
              mb={10}
              label="Email"
              icon={<IconUserCircle color="#cccccc" />}
              placeholder="Email@example.com"
              classNames={{ label: styles.label, input: styles.input, wrapper: styles.wrapper }}
            />

            <PasswordInput
              {...form.getInputProps('password')}
              size="lg"
              w={'80%'}
              maw={400}
              label="Password"
              icon={<IconLock color="#cccccc" />}
              placeholder="Password"
              classNames={{ label: styles.label, input: styles.input, wrapper: styles.wrapper }}
            />

            <Group position="right" w={'80%'} maw={400}>
              <Button
                unstyled
                component="a"
                onClick={() => {
                  forgetPasswordModalHelper.open();
                }}
                classNames={{ root: styles.forgetpassword_label }}
              >
                Forget Password
              </Button>
            </Group>

            <GradientButton
              type="submit"
              loading={handleLogin.isLoading}
              size="md"
              w={'80%'}
              mt={20}
              maw={300}
            >
              Sign In
            </GradientButton>
          </form>
          <ForgotPassword opened={forgetPasswordOpened} onClose={forgetPasswordModalHelper.close} />
        </Center>
      </Box>
    </SimpleGrid>
  );
};
