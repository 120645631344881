import {
  ActionIcon,
  Anchor,
  Breadcrumbs,
  Box,
  Button,
  Center,
  Group,
  Flex,
  Image,
  Stack,
  Title,
  Text,
  Modal,
  ModalProps,
  NavLink,
  TextInput,
  Container,
  SimpleGrid,
  Radio,
  Stepper,
  Textarea,
  Checkbox,
} from '@mantine/core';
import { API } from 'services';
import { useNavigate, useLocation } from 'react-router-dom';
import DashboardIcon from 'components/icons/DashboardIcon';
import { useEffect, useState } from 'react';
import { GradientButton } from 'components/GradientButton';
import { useQuery } from '@tanstack/react-query';
import { useLoginUser } from 'redux/selectors/useLoginUser';
import ppImage from 'assets/inner/pp.png';
import wsgImage from 'assets/inner/TRAQOM/wsglogo.png';
import eyeImage from 'assets/inner/icon-eye.png';
import { ReactComponent as BookSvg } from 'assets/inner/icon_book.svg';
import { ReactComponent as LocateSvg } from 'assets/inner/icon_locate.svg';
import { ReactComponent as CalSvg } from 'assets/inner/icon_cal.svg';
import { ReactComponent as ClockSvg } from 'assets/inner/icon_clock.svg';
import { ReactComponent as FileSvg } from 'assets/inner/icon_file.svg';
import { ReactComponent as FeedbackSvg } from 'assets/inner/learner_evaluation/Feedback.svg';
import { ReactComponent as GoogleSvg } from 'assets/inner/google_review/g logo.svg';
import { ReactComponent as GoogleReviewSvg } from 'assets/inner/google_review/g Review.svg';
import { ReactComponent as AttendanceSvg } from 'assets/inner/e-attendance/e-attendance.svg';
import { ReactComponent as TraqomSvg } from 'assets/inner/TRAQOM/TRAQOM.svg';
import { ReactComponent as ArrowSvg } from 'assets/inner/movement_icon/arrow.svg';
import { ReactComponent as Bg01Svg } from 'assets/inner/movement_icon/bg01.svg';
import { ReactComponent as Bg02Svg } from 'assets/inner/movement_icon/bg02.svg';
import { ReactComponent as Bg03Svg } from 'assets/inner/movement_icon/bg03.svg';
import { DataTable } from 'components/DataTable';
import { ReactComponent as RatingSvg } from 'assets/dashboard/icon_traqom.svg';
import { useDisclosure } from '@mantine/hooks';
import { useForm } from '@mantine/form';
import loading from 'assets/report/loading.gif';
// import { AcknowledgeModal } from '../Course/acknowledgeModal';

import JSZip from 'jszip';

import QRCode from 'qrcode.react';

const CourseDetails = (courseDetail: any) => {
  const { loginUser } = useLoginUser();
  const { search } = useLocation();

  // const title = "";
  if (courseDetail && courseDetail.data) {
    return (
      <Box p={20}>
        <Title order={3}>{courseDetail.data.course_title}</Title>
        <hr></hr>
        <Flex py={20} className="sm_direction_column">
          <Flex>
            {loginUser.trainer_image_url ? (
              <Image src={loginUser.trainer_image_url} width="80" className="profile_image"></Image>
            ) : (
              <Image src={ppImage} width="80" className="profile_image"></Image>
            )}
            <Box px={20}>
              <Title order={2}>
                <Text display={'inline'} color="#8c4799" ml={4}>
                  {loginUser.trainer_name}
                </Text>
              </Title>
              <Text size={'lg'}>WSQ ACLP Certified Trainer</Text>
            </Box>
          </Flex>

          <Flex>
            {courseDetail && courseDetail.data && courseDetail.data.user_profile_img ? (
              <Image
                src={import.meta.env.VITE_API_URL + '/' + courseDetail.data.user_profile_img}
                width="80"
                className="profile_image"
              ></Image>
            ) : (
              <Image src={ppImage} width="80" className="profile_image"></Image>
            )}
            <Box px={20}>
              <Title order={2}>
                <Text display={'inline'} color="#8c4799" ml={4}>
                  {courseDetail.data.user_name}
                </Text>
              </Title>
              <Text size={'lg'}>Student Services Executive</Text>
            </Box>
          </Flex>
        </Flex>
        <Flex className="course_detail flex_wrap">
          <Box>
            <BookSvg />
            <Text fw={700} ml={5} display={'inline'}>
              Course ID
            </Text>
            <Text>{courseDetail.data.course_ref}</Text>
            <BookSvg />
            <Text fw={700} ml={5} display={'inline'}>
              Course TPG ID
            </Text>
            <Text>{courseDetail.data.courserun_apicourserunid}</Text>
            <BookSvg />
            <Text fw={700} ml={5} display={'inline'}>
              Matured Class
            </Text>
            <Text>{courseDetail.data.courserun_bilingual == '1' ? 'Yes' : 'No'}</Text>
          </Box>
          <Box>
            <LocateSvg />
            <Text fw={700} ml={5} display={'inline'}>
              Location
            </Text>
            {courseDetail.data.courserun_modeoftraining === 'Face-to-Face Classroom' ? (
              <Box>
                <Text
                  dangerouslySetInnerHTML={{
                    __html:
                      '<b>' +
                      courseDetail.data.traininglocation_title +
                      '</b><br> ' +
                      '#' +
                      courseDetail.data.venue_floor +
                      '-' +
                      courseDetail.data.venue_unit +
                      ', ' +
                      courseDetail.data.venue_bulding +
                      ' <br>Singapore ' +
                      courseDetail.data.venue_postalcode,
                  }}
                ></Text>
                <Text
                  dangerouslySetInnerHTML={{
                    __html: '<b>Class Room</b><br> ' + courseDetail.data.venue_room,
                  }}
                ></Text>
              </Box>
            ) : (
              <Text>Online Class</Text>
            )}
          </Box>
          <Box>
            <CalSvg />
            <Text fw={700} ml={5} display={'inline'}>
              Course Dates
            </Text>
            <Text>
              {new Date(courseDetail.data.courserun_course_from).toLocaleDateString('en-GB', {
                day: 'numeric',
                month: 'short',
                year: 'numeric',
                weekday: 'short',
              })}{' '}
              -<br></br>
              {new Date(courseDetail.data.courserun_course_to).toLocaleDateString('en-GB', {
                day: 'numeric',
                month: 'short',
                year: 'numeric',
                weekday: 'short',
              })}
            </Text>
          </Box>
          <Box>
            <ClockSvg />
            <Text fw={700} ml={5} display={'inline'}>
              Course Timings
            </Text>
            <Text dangerouslySetInnerHTML={{ __html: courseDetail.data.session }}></Text>
          </Box>
        </Flex>
      </Box>
    );
  } else {
    return '';
  }
};

const CourseDocuments = (courseDetail: any, setLoading: any) => {
  interface FileItem {
    id: string;
    key: string;
    link: string;
    default_filename: string;
    // Add any other properties as needed
  }
  const [selectedFiles, setSelectedFiles] = useState<FileItem[]>([]);

  const handleFileSelection = (file: FileItem) => {
    const selectedIndex = selectedFiles.indexOf(file);
    if (selectedIndex === -1) {
      setSelectedFiles([...selectedFiles, file]);
    } else {
      const updatedSelectedFiles = [...selectedFiles];
      updatedSelectedFiles.splice(selectedIndex, 1);
      setSelectedFiles(updatedSelectedFiles);
    }
  };

  const handleDownloadSelectedFiles = () => {
    // Extract IDs from selected files
    const fileIds = selectedFiles.map((file) => file.id);
    
    if(fileIds.length > 0){
      setLoading(true);
      return API.zipTrainerDocument({
        file: fileIds,
      } as any).then((response) => {
        setLoading(false);
        if (response.data.filepath) {
          // Open the generated ZIP file in a new browser window
          window.open(response.data.filepath, '_blank');
        }
      });
    }
  };

  if (courseDetail && courseDetail.data) {
    return (
      <Box my={20}>
        {courseDetail.data.course_file && courseDetail.data.course_file.length > 0 && (
          <Box>
            <Text c="#7c0094" fw={700} td="underline">
              Plans & Guides
            </Text>
            {courseDetail.data.course_file.map((item: any, index: any) => (
              <Flex my={20} key={item.key}>
                <input
                  type="checkbox"
                  style={{ all: 'revert', marginTop: '-5px' }}
                  checked={selectedFiles.includes(item)}
                  onChange={() => handleFileSelection(item)}
                />

                <FileSvg />
                <Anchor mx={10} href={item.link} target="_blank">
                  {item.default_filename}
                </Anchor>
                <br></br>
              </Flex>
            ))}
          </Box>
        )}
        {courseDetail.data.course_file_asr && courseDetail.data.course_file_asr.length > 0 && (
          <Box>
            <Text c="#7c0094" fw={700} td="underline">
              Assessment Documents & ASR
            </Text>
            {courseDetail.data.course_file_asr.map((item: any, index: any) => (
              <Flex my={20} key={item.key}>
                <input
                  type="checkbox"
                  style={{ all: 'revert', marginTop: '-5px' }}
                  checked={selectedFiles.includes(item)}
                  onChange={() => handleFileSelection(item)}
                />

                <FileSvg />
                <Anchor mx={10} href={item.link} target="_blank">
                  {item.default_filename}
                </Anchor>
                <br></br>
              </Flex>
            ))}
          </Box>
        )}
        {courseDetail.data.course_file_slide && courseDetail.data.course_file_slide.length > 0 && (
          <Box>
            <Text c="#7c0094" fw={700} td="underline">
              Presentation Slides
            </Text>
            {courseDetail.data.course_file_slide.map((item: any, index: any) => (
              <Flex my={20} key={item.key}>
                <input
                  type="checkbox"
                  style={{ all: 'revert', marginTop: '-5px' }}
                  checked={selectedFiles.includes(item)}
                  onChange={() => handleFileSelection(item)}
                />

                <FileSvg />
                <Anchor mx={10} href={item.link} target="_blank">
                  {item.default_filename}
                </Anchor>
                <br></br>
              </Flex>
            ))}
          </Box>
        )}
        {courseDetail.data.course_file_others &&
          courseDetail.data.course_file_others.length > 0 && (
            <Box>
              <Text c="#7c0094" fw={700} td="underline">
                Others
              </Text>
              {courseDetail.data.course_file_others.map((item: any, index: any) => (
                <Flex my={20} key={item.key}>
                  <input
                    type="checkbox"
                    style={{ all: 'revert', marginTop: '-5px' }}
                    checked={selectedFiles.includes(item)}
                    onChange={() => handleFileSelection(item)}
                  />

                  <FileSvg />
                  <Anchor mx={10} href={item.link} target="_blank">
                    {item.default_filename}
                  </Anchor>
                  <br></br>
                </Flex>
              ))}
            </Box>
          )}
        <Button variant="outline" m={4} onClick={handleDownloadSelectedFiles}>
          Download
        </Button>
      </Box>
    );
  } else {
    return '';
  }
};

const acknowledgeSubmission = (data: any) => {
  return API.acknowledgeSubmission({
    courseRunId: data.CourserunId,
  } as any).then((response) => {
    if (response.data.status) {
      //call queryClient
      // queryClient.invalidateQueries(['coursedetail']);
      return true;
    } else {
      return false;
    }
  });
};

const AcknowledgeModel = (props: {
  courseDetail: any;
  newProps: any;
  onClosed: () => void;
}): any => {
  const { courseDetail, newProps, onClosed } = props;
  const form = useForm({
    initialValues: {
      CourseId: '',
      CourserunId: '',
    },
  });
  useEffect(() => {
    if (courseDetail) {
      if (courseDetail.data) {
        form.setValues({
          CourseId: courseDetail.data.course_id,
          CourserunId: courseDetail.data.courserun_id,
        });
      }
    }
  }, [courseDetail?.data?.courserun_id]);

  if (courseDetail && courseDetail.data) {
    return (
      <Modal
        {...newProps}
        size={'xl'}
        closeOnClickOutside={false}
        keepMounted={false}
        withCloseButton={false}
      >
        <Center>
          <Container w="100%" my={30}>
            <Title size={'md'} ta="center" my={20}>
              Please click the acknowledge button for following course.
            </Title>

            <Title size={'md'} ta="center" color="#8c4799">
              {courseDetail.data.course_title}
            </Title>
            <Flex className="course_detail flex_wrap" my={30}>
              <Box>
                <BookSvg />
                <Text fw={700} ml={5} display={'inline'}>
                  Course ID
                </Text>
                <Text>{courseDetail.data.course_ref}</Text>
                <BookSvg />
                <Text fw={700} ml={5} display={'inline'}>
                  Course TPG ID
                </Text>
                <Text>{courseDetail.data.courserun_apicourserunid}</Text>
              </Box>
              <Box>
                <LocateSvg />
                <Text fw={700} ml={5} display={'inline'}>
                  Location
                </Text>
                {courseDetail.data.courserun_modeoftraining === 'Face-to-Face Classroom' ? (
                  <Box>
                    <Text
                      dangerouslySetInnerHTML={{
                        __html:
                          '<b>' +
                          courseDetail.data.traininglocation_title +
                          '</b><br> ' +
                          '#' +
                          courseDetail.data.venue_floor +
                          '-' +
                          courseDetail.data.venue_unit +
                          ', ' +
                          courseDetail.data.venue_bulding +
                          ' <br>Singapore ' +
                          courseDetail.data.venue_postalcode,
                      }}
                    ></Text>
                    <Text
                      dangerouslySetInnerHTML={{
                        __html: '<b>Class Room</b><br> ' + courseDetail.data.venue_room,
                      }}
                    ></Text>
                  </Box>
                ) : (
                  <Text>Online Class</Text>
                )}
              </Box>
              <Box>
                <CalSvg />
                <Text fw={700} ml={5} display={'inline'}>
                  Course Dates
                </Text>
                <Text>
                  {new Date(courseDetail.data.courserun_course_from).toLocaleDateString('en-GB', {
                    day: 'numeric',
                    month: 'short',
                    year: 'numeric',
                    weekday: 'short',
                  })}{' '}
                  -<br></br>
                  {new Date(courseDetail.data.courserun_course_to).toLocaleDateString('en-GB', {
                    day: 'numeric',
                    month: 'short',
                    year: 'numeric',
                    weekday: 'short',
                  })}
                </Text>
              </Box>
              <Box>
                <ClockSvg />
                <Text fw={700} ml={5} display={'inline'}>
                  Course Timings
                </Text>
                <Text dangerouslySetInnerHTML={{ __html: courseDetail.data.session }}></Text>
              </Box>
            </Flex>
            <Box mt={50}>
              {/* <form onSubmit={form.onSubmit(acknowledgeSubmission)}> */}
              <form
                onSubmit={form.onSubmit(function (data) {
                  acknowledgeSubmission(data).then(function (value) {
                    onClosed();
                  });
                })}
              >
                <TextInput
                  style={{ display: 'none' }}
                  size="md"
                  contentEditable={false}
                  label="CourseId"
                  placeholder="CourseId"
                  // value={courseDetail.data.courserun_id}
                  {...form.getInputProps('CourseId')}
                />
                <TextInput
                  style={{ display: 'none' }}
                  size="md"
                  contentEditable={false}
                  label="CourserunId"
                  placeholder="CourserunId"
                  // value={courseDetail.data.courserun_id}
                  {...form.getInputProps('CourserunId')}
                />
                <Group position="apart" mt="lg">
                  <Button w="100%" type="submit">
                    Acknowledge
                  </Button>
                </Group>
              </form>
            </Box>
          </Container>
        </Center>
      </Modal>
    );
  } else {
    return '';
  }
};

export function CoursePage(props: any) {
  const [isLoading, setLoading] = useState(false);
  const [acknowledgeModalOpen, acknowledgeModalHandler] = useDisclosure(false);
  const [active, setActive] = useState(0);

  const navigate = useNavigate();
  const items = [
    {
      title: (
        <ActionIcon>
          <DashboardIcon />
        </ActionIcon>
      ),
      href: '/dashboard',
    },
    { title: 'Course', href: location.pathname },
  ].map((item, index) => (
    <Anchor
      key={index}
      onClick={() => {
        navigate(item.href);
      }}
    >
      {item.title}
    </Anchor>
  ));

  const [activeTab, setActiveTab] = useState('learner');
  const handleTabChange = (tab: any) => {
    setActiveTab(tab);
  };
  const [activeTSTab, setActiveTSTab] = useState('');
  const handleTSTabChange = (tab: any) => {
    setActiveTSTab(tab);
  };

  const [activeTrainerTab, setActiveTrainerTab] = useState('learner');
  const handleTrainerTabChange = (tab: any) => {
    setActiveTrainerTab(tab);
  };
  const [activeLearnerTab, setActiveLearnerTab] = useState('attendance');
  const handleLearnerTabChange = (tab: any) => {
    setActiveLearnerTab(tab);
  };
  interface ShowFullEmailState {
    [learnerId: string]: boolean;
  }

  const [showFullEmail, setShowFullEmail] = useState<ShowFullEmailState>({});
  const toggleFullEmail = (learnerId: any) => {
    setShowFullEmail((prevState) => ({
      ...prevState,
      [learnerId]: !prevState[learnerId],
    }));
  };

  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const courseId = queryParams.get('course');
  const courseRunId = queryParams.get('course_run');

  const learner_evaluation_url = `${import.meta.env.VITE_URL}/evaluation?course_run=${courseRunId}`;

  const learners = useQuery(['learner'], () => {
    return API.getCourseLearner({
      courserun_id: courseRunId,
    }).then((res) => res.data);
  });

  const assessments = useQuery(['assessment'], () => {
    return API.getLearnerAssessment({
      courserun_id: courseRunId,
    }).then((res) => res.data);
  });

  const courseDetail = useQuery(['coursedetail', courseId], () => {
    return API.getCourseDetail({
      course_id: courseId,
      courserun_id: courseRunId,
    }).then((res) => res.data);
  });

  const courseDetails = CourseDetails(courseDetail);
  const courseDocuments = CourseDocuments(courseDetail, setLoading);

  let attendanceQR = '';
  let googleReview = '';
  let traqomSurvey = '';
  let traqom_rating = '';
  let evaluation_rating = '';

  if (courseDetail && courseDetail.data && attendanceQR === '') {
    attendanceQR = courseDetail.data.courserun_attendance_link;
  }
  if (courseDetail && courseDetail.data && googleReview == '') {
    googleReview = courseDetail.data.google_review;
  }
  if (courseDetail && courseDetail.data && traqomSurvey == '') {
    traqomSurvey = courseDetail.data.traqom_survey;
  }
  if (courseDetail && courseDetail.data && traqom_rating == '') {
    traqom_rating = courseDetail.data.courserun_traqom_rating;
  }
  if (courseDetail && courseDetail.data && evaluation_rating == '') {
    evaluation_rating = courseDetail.data.evaluation_rating;
  }

  // useEffect(() => {
  //   if (courseDetail) {
  //     if (courseDetail.data && !courseDetail.data.courserun_acknowledge) {
  //       // acknowledgeModalHandler.open();
  //     }
  //   }
  // });

  const newProps = { ...props };

  if (courseDetail.data && !courseDetail.data.courserun_acknowledge) {
    newProps.opened = true;
  }

  // const acknowledgeModel = AcknowledgeModel(courseDetail, newProps);

  const mandonaly = (
    <span
      className="mantine-103svbs mantine-InputWrapper-required mantine-TextInput-required"
      aria-hidden="true"
    >
      {' '}
      *
    </span>
  );
  
  const tss = courseDetail.data ? Array.from(new Set(courseDetail.data.ts.map((item:any) => item.ts_id))) : [];
  // console.log(days);

  useEffect(() => {
    // Only set activeTab if it hasn't been set yet
    if (tss.length > 0 && activeTSTab === '') {
      setActiveTSTab(`Day${tss[0]}`);
    }
  }, [tss]);

  const [tsWithErrors, setTsWithErrors] = useState<Set<number>>(new Set());
  
  const evaluationForm = useForm({
    initialValues: {
      ...tss.reduce((acc: Record<string, string>, ts) => {
        acc[`section1_relevant${ts}`] = '';
        acc[`section1_knowledge${ts}`] = '';
        acc[`section1_facilities${ts}`] = '';
        acc[`section1_comments${ts}`] = '';
        acc[`section1_ts_attitude${ts}`] = '';
        acc[`section1_ts_supportive${ts}`] = '';
        acc[`section2_materials${ts}`] = '';
        acc[`section2_smoothly${ts}`] = '';
        acc[`section2_efficiently${ts}`] = '';
        acc[`section3_feedbacks${ts}`] = '';
        acc[`section3_help${ts}`] = '';
        acc[`section3_assisted${ts}`] = '';
        acc[`section3_ts_effectively${ts}`] = '';
        return acc;
      }, {}),
      
      // Add section4 values separately
      section4_helpful: '',
      section4_relevant: '',
      section4_knowledge: '',
      section4_facilities: '',
      section4_comments: '',
    },
  
    validate: (values: any) => {
      const errors: Record<string, string> = {};
      const errorTss = new Set<any>();
  
      // Iterate over dynamic ts-based fields for validation
      tss.forEach((ts) => {
        if (!values[`section1_ts_attitude${ts}`]) {
          errors[`section1_ts_attitude${ts}`] = 'Please select rating';
          errorTss.add(ts);
        }
        if (!values[`section1_ts_supportive${ts}`]) {
          errors[`section1_ts_supportive${ts}`] = 'Please select rating';
          errorTss.add(ts);
        }
        if (!values[`section2_smoothly${ts}`]) {
          errors[`section2_smoothly${ts}`] = 'Please select rating';
          errorTss.add(ts);
        }
        if (!values[`section2_efficiently${ts}`]) {
          errors[`section2_efficiently${ts}`] = 'Please select rating';
          errorTss.add(ts);
        }
        if (!values[`section3_feedbacks${ts}`]) {
          errors[`section3_feedbacks${ts}`] = 'Please select rating';
          errorTss.add(ts);
        }
        if (!values[`section3_help${ts}`]) {
          errors[`section3_help${ts}`] = 'Please select rating';
          errorTss.add(ts);
        }
        if (!values[`section3_assisted${ts}`]) {
          errors[`section3_assisted${ts}`] = 'Please select rating';
          errorTss.add(ts);
        }
        if (!values[`section3_ts_effectively${ts}`]) {
          errors[`section3_ts_effectively${ts}`] = 'Please select rating';
          errorTss.add(ts);
        }
      });
  
      // Validate section4 fields
      if (!values.section4_relevant) {
        errors.section4_relevant = 'Please select rating';
      }
      if (!values.section4_knowledge) {
        errors.section4_knowledge = 'Please select rating';
      }
      if (!values.section4_facilities) {
        errors.section4_facilities = 'Please select rating';
      }
  
      setTsWithErrors(errorTss); // Update the days with errors
      return errors;
    },
  });

      // if(courseDetail.data.trainer_evaluations_count == "5"){
      //   evaluationForm.setValues({
      //     section4_helpful: courseDetail.data.trainer_evaluations['section4_helpful'],
      //     section4_relevant: courseDetail.data.trainer_evaluations['section4_relevant'],
      //     section4_knowledge: courseDetail.data.trainer_evaluations['section4_knowledge'],
      //     section4_facilities: courseDetail.data.trainer_evaluations['section4_facilities'],
      //     section4_comments: courseDetail.data.trainer_evaluations['section4_comments'],
      //   });
      // }else{
      // }
      useEffect(() => {
        if (
            courseDetail.data &&
            courseDetail.data.trainer_evaluations_count &&
            Array.isArray(courseDetail.data.trainer_evaluations)
        ) {
            courseDetail.data.trainer_evaluations.forEach((item: any) => {
                const tsId = item.ts_id;
                if (item) {
                    evaluationForm.setValues((prevValues) => ({
                        ...prevValues,
                        [`section1_ts_attitude${tsId}`]: item.section1_ts_attitude,
                        [`section1_ts_supportive${tsId}`]: item.section1_ts_supportive,
                        [`section2_materials${tsId}`]: item.section2_materials,
                        [`section2_smoothly${tsId}`]: item.section2_smoothly,
                        [`section2_efficiently${tsId}`]: item.section2_efficiently,
                        [`section3_feedbacks${tsId}`]: item.section3_feedbacks,
                        [`section3_help${tsId}`]: item.section3_help,
                        [`section3_assisted${tsId}`]: item.section3_assisted,
                        [`section3_ts_effectively${tsId}`]: item.section3_ts_effectively,
                        [`section4_helpful`]: item.section4_helpful,
                        [`section4_relevant`]: item.section4_relevant,
                        [`section4_knowledge`]: item.section4_knowledge,
                        [`section4_facilities`]: item.section4_facilities,
                        [`section4_comments`]: item.section4_comments,
                    }));
                }
            });
        }else if (
          courseDetail.data &&
          courseDetail.data.trainer_evaluations_count
        ) {
          courseDetail.data.ts.forEach((item: any) => {
            const tsId = item.ts_id;
            const evaluationData = courseDetail.data.trainer_evaluations[tsId];

            if (evaluationData) {
                evaluationForm.setValues((prevValues) => ({
                    ...prevValues,
                    [`section1_ts_attitude${tsId}`]: evaluationData.section1_ts_attitude,
                    [`section1_ts_supportive${tsId}`]: evaluationData.section1_ts_supportive,
                    [`section2_materials${tsId}`]: evaluationData.section2_materials,
                    [`section2_smoothly${tsId}`]: evaluationData.section2_smoothly,
                    [`section2_efficiently${tsId}`]: evaluationData.section2_efficiently,
                    [`section3_feedbacks${tsId}`]: evaluationData.section3_feedbacks,
                    [`section3_help${tsId}`]: evaluationData.section3_help,
                    [`section3_assisted${tsId}`]: evaluationData.section3_assisted,
                    [`section3_ts_effectively${tsId}`]: evaluationData.section3_ts_effectively,
                    [`section4_helpful`]: evaluationData.section4_helpful,
                    [`section4_relevant`]: evaluationData.section4_relevant,
                    [`section4_knowledge`]: evaluationData.section4_knowledge,
                    [`section4_facilities`]: evaluationData.section4_facilities,
                    [`section4_comments`]: evaluationData.section4_comments,
                }));
            }
          });
        }
    }, [courseDetail.data, evaluationForm]);


  const handleSubmitSection1 = (data: any) => {
    return API.trainerEvaluationSubmission({
      answers: data,
      courseRunId: courseRunId,
    } as any).then((response) => {
      if (response.data.status) {
        setActive(1);
        courseDetail.refetch();
      }
    });
  };

  const prevStep = () => {
    setActive(active - 1);
  };
  const [checked, setChecked] = useState(true);
  return (
    <Stack px={{ base: 0, md: 30 }}>
      {isLoading && (
        <Box className={'loading_box'}>
          <Image src={loading} width="100"></Image>
        </Box>
      )}
      <AcknowledgeModel
        courseDetail={courseDetail}
        newProps={newProps}
        onClosed={function () {
          courseDetail.refetch();
        }}
      />

      <Breadcrumbs>{items}</Breadcrumbs>
      <Box bg="#fff">
        {/* Call the CourseDetails component */}
        {courseDetails}

        <Box className="detail_box" px={20}>
          <Button
            variant="outline"
            m={4}
            onClick={() => handleTabChange('learner')}
            className={activeTab === 'learner' ? 'active' : ''}
          >
            For Learner
          </Button>
          <Button
            variant="outline"
            m={4}
            onClick={() => handleTabChange('trainer')}
            className={activeTab === 'trainer' ? 'active' : ''}
          >
            For Trainer
          </Button>

          <hr className="light_hr"></hr>

          <Box className="" my={20}>
            {activeTab === 'learner' && (
              <Box>
                <Title order={3}>For Learner</Title>

                <Button
                  variant="outline"
                  m={4}
                  onClick={() => handleLearnerTabChange('attendance')}
                  className={activeLearnerTab === 'attendance' ? 'active' : ''}
                >
                  e-Attendance
                </Button>
                <Button
                  variant="outline"
                  m={4}
                  onClick={() => handleLearnerTabChange('evaluation')}
                  className={activeLearnerTab === 'evaluation' ? 'active' : ''}
                >
                  Learner Evaluation
                </Button>
                <Button
                  variant="outline"
                  m={4}
                  onClick={() => handleLearnerTabChange('google')}
                  className={activeLearnerTab === 'google' ? 'active' : ''}
                >
                  Google Review
                </Button>
                <Button
                  variant="outline"
                  m={4}
                  onClick={() => handleLearnerTabChange('traqom')}
                  className={activeLearnerTab === 'traqom' ? 'active' : ''}
                >
                  TRAQOM Survey
                </Button>

                <Box my={20}>
                  {activeLearnerTab === 'attendance' && (
                    <Box>
                      <Title order={4}>E-Attendance</Title>
                      <text>Scan QR Code for Learner Attendance / </text>
                      {courseDetail.data && courseDetail.data.courserun_attendance_link ? (
                        <a
                          href={courseDetail.data.courserun_attendance_link}
                          style={{ cursor: 'pointer' }}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {courseDetail.data.courserun_attendance_link}
                        </a>
                      ) : (
                        <text></text>
                      )}

                      <Title order={4} my={20}>
                        <span style={{ color: '#8c4799' }}>Attendance Taking</span> Made Quick and
                        Easy
                      </Title>
                      <Box my={50} style={{ position: 'relative' }}>
                        <Flex className={'feedbackBox purpleBackground'}>
                          <Box
                            p={30}
                            pt={40}
                            className={'purpletheme'}
                            style={{
                              backgroundColor: '#fff',
                              border: '2px solid #8c4799',
                              borderRadius: '20px',
                              width: '260px',
                            }}
                          >
                            <Box className={'qr_title'}>
                              <text>
                                {' '}
                                e-Attendance<br></br>Link
                              </text>
                            </Box>
                            {attendanceQR != '' && attendanceQR != null ? (
                              <Box my={20}>
                                <QRCode
                                  value={attendanceQR}
                                  size={200}
                                  fgColor="#000"
                                  bgColor="#fff"
                                />
                              </Box>
                            ) : (
                              <Box my={20}>
                                <text>QR Code not ready yet</text>
                              </Box>
                            )}
                          </Box>
                          <Box p={30} style={{ width: 'calc(100% - 260px)' }}>
                            <AttendanceSvg />
                            <Box my={20}>
                              <text>Please scan the QR code for learner attendance.</text>
                              <Box my={20}>
                                <BookSvg className={'width_auto'} />
                                <Text fw={700} ml={5} display={'inline'}>
                                  Course TPG ID
                                </Text>
                                {courseDetail.data && courseDetail.data.courserun_apicourserunid ? (
                                  <Text>{courseDetail.data.courserun_apicourserunid}</Text>
                                ) : null}
                              </Box>
                            </Box>
                          </Box>
                        </Flex>
                        <Box className={'updown arrow_1'}>
                          <ArrowSvg />
                        </Box>
                        <Box className={'updown5 love_icon'}>
                          <Bg02Svg />
                        </Box>
                        <Box className={'smile_icon'}>
                          <Bg03Svg />
                        </Box>
                        <Box className={'updown4 arrow_2'}>
                          <ArrowSvg />
                        </Box>
                        <Box className={'updown2 right_icon'}>
                          <Bg01Svg />
                        </Box>
                        <Box className={'updown3 arrow_3'}>
                          <ArrowSvg />
                        </Box>
                      </Box>
                    </Box>
                  )}
                  {activeLearnerTab === 'evaluation' && (
                    <Box>
                      <Title order={4}>Learner Evaluation</Title>
                      <text>Scan QR Code for Learner Evaluation</text>

                      <Title order={4} my={20}>
                        <span style={{ color: '#00ab84' }}>We&apos;d Love To Hear Your</span>{' '}
                        Feedback
                      </Title>
                      <Box my={50} style={{ position: 'relative' }}>
                        <Flex className={'feedbackBox greenBackground'}>
                          <Box
                            p={30}
                            pt={40}
                            className={'greentheme'}
                            style={{
                              backgroundColor: '#fff',
                              border: '2px solid #00ab84',
                              borderRadius: '20px',
                              width: '260px',
                            }}
                          >
                            <Box className={'qr_title'}>
                              <text>
                                Course Evaluation<br></br>Feedback From{' '}
                              </text>
                            </Box>
                            <QRCode
                              value={learner_evaluation_url}
                              size={200}
                              fgColor="#000"
                              bgColor="#fff"
                            />
                          </Box>
                          <Box p={30} style={{ width: 'calc(100% - 260px)' }}>
                            <FeedbackSvg />
                            <Box my={20}>
                              <text>
                                Tell us more about the experience you have had (course registration
                                process, trainers facilitation, curriculum, key takeaways from the
                                course etc.)
                              </text>
                            </Box>
                          </Box>
                        </Flex>
                        <Box className={'updown arrow_1'}>
                          <ArrowSvg />
                        </Box>
                        <Box className={'updown5 love_icon'}>
                          <Bg02Svg />
                        </Box>
                        <Box className={'smile_icon'}>
                          <Bg03Svg />
                        </Box>
                        <Box className={'updown4 arrow_2'}>
                          <ArrowSvg />
                        </Box>
                        <Box className={'updown2 right_icon'}>
                          <Bg01Svg />
                        </Box>
                        <Box className={'updown3 arrow_3'}>
                          <ArrowSvg />
                        </Box>
                      </Box>
                    </Box>
                  )}
                  {activeLearnerTab === 'google' && (
                    <Box>
                      <Title order={4}>Google Review</Title>
                      <text>Scan QR Code for Google Review</text>

                      <Title order={4} my={20}>
                        <span style={{ color: '#0285ca' }}>Write Us a </span> Review
                      </Title>
                      <Box my={50} style={{ position: 'relative' }}>
                        <Flex className={'feedbackBox blueBackground'}>
                          <Box
                            p={30}
                            pt={40}
                            className={'bluetheme'}
                            style={{
                              backgroundColor: '#fff',
                              border: '2px solid #0285ca',
                              borderRadius: '20px',
                              width: '260px',
                            }}
                          >
                            <Box className={'qr_title'}>
                              <GoogleSvg />
                              <text>
                                {' '}
                                Google Review<br></br>Link
                              </text>
                            </Box>
                            {googleReview != '' && googleReview != null ? (
                              <Box my={20}>
                                <QRCode
                                  value={googleReview}
                                  size={200}
                                  fgColor="#000"
                                  bgColor="#fff"
                                />
                              </Box>
                            ) : (
                              <Box my={20}>
                                <text>QR Code not ready yet</text>
                              </Box>
                            )}
                          </Box>
                          <Box p={30} style={{ width: 'calc(100% - 260px)' }}>
                            <GoogleReviewSvg />
                            <Box my={20}>
                              <text>
                                If you enjoyed the course and found it useful, please write us a
                                Google review.
                              </text>
                            </Box>
                          </Box>
                        </Flex>
                        <Box className={'updown arrow_1'}>
                          <ArrowSvg />
                        </Box>
                        <Box className={'updown5 love_icon'}>
                          <Bg02Svg />
                        </Box>
                        <Box className={'smile_icon'}>
                          <Bg03Svg />
                        </Box>
                        <Box className={'updown4 arrow_2'}>
                          <ArrowSvg />
                        </Box>
                        <Box className={'updown2 right_icon'}>
                          <Bg01Svg />
                        </Box>
                        <Box className={'updown3 arrow_3'}>
                          <ArrowSvg />
                        </Box>
                      </Box>
                    </Box>
                  )}
                  {activeLearnerTab === 'traqom' && (
                    <Box>
                      <Title order={4}>TRAQOM Survey</Title>
                      <text>Scan QR Code for TRAQOM Survey</text>

                      <Title order={4} my={20} className={'wsg_box'}>
                        <span
                          style={{
                            color: '#cf188a',
                            textDecoration: 'underline',
                            marginRight: '3px',
                          }}
                        >
                          Mandatory{' '}
                        </span>{' '}
                        Quality Survey by
                        <Image src={wsgImage} width="150" ml={10} mt={-10}></Image>
                      </Title>
                      <Box my={50} style={{ position: 'relative' }}>
                        <Flex className={'feedbackBox redBackground'}>
                          <Box
                            p={30}
                            pt={40}
                            className={'redtheme'}
                            style={{
                              backgroundColor: '#fff',
                              border: '2px solid #cf188a',
                              borderRadius: '20px',
                              width: '260px',
                            }}
                          >
                            <Box className={'qr_title'}>
                              <text>
                                TRAQOM<br></br>Survey Link
                              </text>
                            </Box>
                            {traqomSurvey != '' && traqomSurvey != null ? (
                              <Box my={20}>
                                <QRCode
                                  value={traqomSurvey}
                                  size={200}
                                  fgColor="#000"
                                  bgColor="#fff"
                                />
                              </Box>
                            ) : (
                              <Box my={20}>
                                <text>QR Code not ready yet</text>
                              </Box>
                            )}
                          </Box>
                          <Box p={30} style={{ width: 'calc(100% - 260px)' }}>
                            <TraqomSvg />
                            <Box my={20}>
                              <text>
                                To ensure quality and outcome of the training, SSG has mandated the
                                participation in the quality survey.
                                <br></br>
                                <br></br>Please scan the link to complete the survey
                              </text>
                              <Box my={20}>
                                <BookSvg className={'width_auto'} />
                                <Text fw={700} ml={5} display={'inline'}>
                                  Course TPG ID
                                </Text>
                                {courseDetail.data && courseDetail.data.courserun_apicourserunid ? (
                                  <Text>{courseDetail.data.courserun_apicourserunid}</Text>
                                ) : null}
                              </Box>
                            </Box>
                          </Box>
                        </Flex>
                        <Box className={'updown arrow_1'}>
                          <ArrowSvg />
                        </Box>
                        <Box className={'updown5 love_icon'}>
                          <Bg02Svg />
                        </Box>
                        <Box className={'smile_icon'}>
                          <Bg03Svg />
                        </Box>
                        <Box className={'updown4 arrow_2'}>
                          <ArrowSvg />
                        </Box>
                        <Box className={'updown2 right_icon'}>
                          <Bg01Svg />
                        </Box>
                        <Box className={'updown3 arrow_3'}>
                          <ArrowSvg />
                        </Box>
                      </Box>
                    </Box>
                  )}
                </Box>
              </Box>
            )}
            {activeTab === 'trainer' && (
              <Box className="trainer_box">
                <Title order={3}>For Trainer</Title>

                <Button
                  variant="outline"
                  m={4}
                  onClick={() => handleTrainerTabChange('learner')}
                  className={activeTrainerTab === 'learner' ? 'active' : ''}
                >
                  Learner&apos;s Information
                </Button>
                <Button
                  variant="outline"
                  m={4}
                  onClick={() => handleTrainerTabChange('upload')}
                  className={activeTrainerTab === 'upload' ? 'active' : ''}
                >
                  Course Document
                </Button>
                <Button
                  variant="outline"
                  m={4}
                  onClick={() => handleTrainerTabChange('traqom')}
                  className={activeTrainerTab === 'traqom' ? 'active' : ''}
                >
                  TRAQOM Rating
                </Button>
                {/* <Button variant="outline" m={4} onClick={() => handleTrainerTabChange('assessment')} className={activeTrainerTab === 'assessment' ? 'active' : ''}>Assessment Records</Button> */}
                <Button
                  variant="outline"
                  m={4}
                  onClick={() => handleTrainerTabChange('evaluation')}
                  className={activeTrainerTab === 'evaluation' ? 'active' : ''}
                >
                  Evaluation Rating
                </Button>
                <Button
                  variant="outline"
                  m={4}
                  onClick={() => handleTrainerTabChange('trainer_evaluation')}
                  className={activeTrainerTab === 'trainer_evaluation' ? 'active' : ''}
                >
                  Trainer Evaluation
                </Button>

                <Box my={20}>
                  {activeTrainerTab === 'learner' && (
                    <Box>
                      <Title order={4}>{`Learner's Information`}</Title>
                      <hr></hr>
                      <DataTable
                        className={'learner_table'}
                        records={learners ? learners.data : []}
                        // define columns
                        columns={[
                          { accessor: 'sequence_number', sortable: false, title: '#' },
                          { accessor: 'learner_name', sortable: false, title: 'Name' },
                          { accessor: 'learner_nricmask', sortable: false, title: 'NRIC' },
                          // { accessor: 'learner_mobile', sortable: true, 'title': 'Mobile' },
                          {
                            accessor: 'learner_email',
                            sortable: false,
                            title: 'Email',
                            render: (value: any) => (
                              <Flex>
                                <Box
                                  className={`learner_email learner_email_${value.learner_id}`}
                                  style={{
                                    display: showFullEmail[value.learner_id] ? 'none' : 'block',
                                  }}
                                >
                                  {value.learner_email}
                                </Box>
                                <Box
                                  className={`learner_email_full learner_email_full_${value.learner_id}`}
                                  style={{
                                    display: showFullEmail[value.learner_id] ? 'block' : 'none',
                                  }}
                                >
                                  {value.learner_email_full}
                                </Box>
                                <Image
                                  src={eyeImage}
                                  width="20"
                                  className="eye_image"
                                  mx={10}
                                  onClick={() => toggleFullEmail(value.learner_id)}
                                />
                              </Flex>
                            ),
                          },
                          {
                            accessor: 'learner_designation',
                            sortable: false,
                            title: 'Designation',
                          },
                        ]}
                      />
                    </Box>
                  )}
                  {activeTrainerTab === 'upload' && (
                    <Box>
                      <Title order={4}>Course Document</Title>
                      {courseDocuments}
                    </Box>
                  )}
                  {activeTrainerTab === 'traqom' && (
                    <Box>
                      <Title order={4}>TRAQOM Rating</Title>
                      <Flex my={20} className="flex_align_center">
                        <Box>
                          <Text fw={800} size={'lg'} color="#8c4799">
                            {traqom_rating} /{' '}
                            <Text display={'inline'} fw={500} color="#8c4799">
                              5
                            </Text>
                          </Text>
                          <Text fw={600}>TRAQOM Rating</Text>
                        </Box>
                        <Box mx={30}>
                          <RatingSvg />
                        </Box>
                      </Flex>
                    </Box>
                  )}
                  {activeTrainerTab === 'assessment' && (
                    <Box>
                      <Title order={4}>Assessment Records</Title>
                      <hr></hr>
                      <DataTable
                        // fetching={isFetching}
                        // page={page}
                        // onPageChange={setPage}
                        // totalRecords={data?.total}
                        // recordsPerPage={data?.page_size || 25}
                        // sortStatus={sortStatus}
                        // sortStatus={0}
                        // onSortStatusChange={handleSortStatusChange}
                        records={assessments ? assessments.data : []}
                        // define columns
                        columns={[
                          { accessor: 'learner_name', sortable: false, title: 'Learner Name' },
                          {
                            accessor: 'assessment_file_name',
                            sortable: false,
                            title: 'File',
                            render: (value: any) => {
                              return (
                                <div
                                  dangerouslySetInnerHTML={{ __html: value.assessment_file_name }}
                                ></div>
                              );
                            },
                          },
                          { accessor: 'created_at', sortable: false, title: 'Upload Date' },
                        ]}
                      />
                    </Box>
                  )}
                  {activeTrainerTab === 'evaluation' && (
                    <Box>
                      <Title order={4}>Evaluation Rating</Title>
                      <Flex my={20} className="flex_align_center">
                        <Box>
                          <Text fw={800} size={'lg'} color="#8c4799">
                            {evaluation_rating} /{' '}
                            <Text display={'inline'} fw={500} color="#8c4799">
                              6
                            </Text>
                          </Text>
                          <Text fw={600}>Evaluation Rating</Text>
                        </Box>
                        <Box mx={30}>
                          <RatingSvg />
                        </Box>
                      </Flex>

                      {courseDetail.data && courseDetail.data.course_evalutions ? (
                        <Box>
                          <hr></hr>
                          <DataTable
                            className={'evaluation_table'}
                            records={courseDetail.data ? courseDetail.data.course_evalutions : []}
                            // define columns
                            columns={[
                              // { accessor: 'participant_name', sortable: false, 'title': 'Name' },
                              { accessor: 'knowledge', sortable: false, title: 'Q1 Knowledge' },
                              { accessor: 'studies', sortable: false, title: 'Q2 Studies' },
                              { accessor: 'delivery', sortable: false, title: 'Q3 Delivery' },
                              { accessor: 'engagement', sortable: false, title: 'Q4 Engagement' },
                              { accessor: 'best', sortable: false, title: 'Positive' },
                              { accessor: 'least', sortable: false, title: 'Negative' },
                            ]}
                          />
                        </Box>
                      ) : null}
                    </Box>
                  )}
                  {activeTrainerTab === 'trainer_evaluation' && (
                    <Box>
                      <Flex my={20} className="flex_align_center">
                        <Stepper active={active} onStepClick={setActive} w={'100%'}>
                          <Stepper.Step>
                            <form
                              onSubmit={evaluationForm.onSubmit(handleSubmitSection1)}
                              style={{ width: '100%' }}
                            >                            
                            <Title order={3}>Trainer Evaluation</Title>
                              
                              

                              {courseDetail.data && courseDetail.data.trainer_evaluations_count == "5" ? (
                                <Flex w="100%">
                                  <Box>
                                    {/* S4 - Q1 */}
                                    <Flex
                                      w="100%"
                                      p={20}
                                      my={10}
                                      direction="row"
                                      wrap="wrap"
                                      className="question_box"
                                    >
                                      <Text>
                                        Tell us more about the experience you have had by answering the
                                        questions below.
                                      </Text>
                                      <Box w="100%" my={20}>
                                        <SimpleGrid cols={2} breakpoints={[{ maxWidth: 'md', cols: 1 }]}>
                                          <Text fw={700}>
                                            Our Training Support are friendly and helpful and provided good assistance in administrative and operations matters. {mandonaly}
                                          </Text>
                                          <Flex className="custom_radio flex_align_end flex_justify_end md_flex_justify_center">
                                            <Text className="strong_agree" fw={500} ta="center" mx={10}>
                                              Strongly<br></br>Agree
                                            </Text>
                                            <Radio.Group
                                              name="section4_helpful"
                                              {...evaluationForm.getInputProps('section4_helpful')}
                                            >
                                              <Group mt="xs">
                                                <Radio value="6" label="6" />
                                                <Radio value="5" label="5" />
                                                <Radio value="4" label="4" />
                                                <Radio value="3" label="3" />
                                                <Radio value="2" label="2" />
                                                <Radio value="1" label="1" />
                                              </Group>
                                            </Radio.Group>
                                            <Text
                                              className="strong_disagree"
                                              fw={500}
                                              ta="center"
                                              mx={10}
                                            >
                                              Strongly<br></br>Disagree
                                            </Text>
                                          </Flex>
                                        </SimpleGrid>
                                        {evaluationForm.errors.section4_helpful && (
                                          <p style={{ color: 'red' }}>
                                            {evaluationForm.errors.section4_helpful}
                                          </p>
                                        )}
                                      </Box>
                                    </Flex>
                                    {/* Q2 */}
                                    <Flex
                                      w="100%"
                                      p={20}
                                      my={10}
                                      direction="row"
                                      wrap="wrap"
                                      className="question_box"
                                    >
                                      <Box w="100%" my={20}>
                                        <SimpleGrid cols={2} breakpoints={[{ maxWidth: 'md', cols: 1 }]}>
                                          <Text fw={700}>
                                            Our Course Content is relevant and up to date. {mandonaly}
                                          </Text>
                                          <Flex className="custom_radio flex_align_end flex_justify_end md_flex_justify_center">
                                            <Text className="strong_agree" fw={500} ta="center" mx={10}>
                                              Strongly<br></br>Agree
                                            </Text>
                                            <Radio.Group
                                              name="section4_relevant"
                                              {...evaluationForm.getInputProps('section4_relevant')}
                                            >
                                              <Group mt="xs">
                                                <Radio value="6" label="6" />
                                                <Radio value="5" label="5" />
                                                <Radio value="4" label="4" />
                                                <Radio value="3" label="3" />
                                                <Radio value="2" label="2" />
                                                <Radio value="1" label="1" />
                                              </Group>
                                            </Radio.Group>
                                            <Text
                                              className="strong_disagree"
                                              fw={500}
                                              ta="center"
                                              mx={10}
                                            >
                                              Strongly<br></br>Disagree
                                            </Text>
                                          </Flex>
                                        </SimpleGrid>
                                        {evaluationForm.errors.section4_relevant && (
                                          <p style={{ color: 'red' }}>
                                            {evaluationForm.errors.section4_relevant}
                                          </p>
                                        )}
                                      </Box>
                                    </Flex>
                                    {/* Q3 */}
                                    <Flex
                                      w="100%"
                                      p={20}
                                      my={10}
                                      direction="row"
                                      wrap="wrap"
                                      className="question_box"
                                    >
                                      <Box w="100%" my={20}>
                                        <SimpleGrid cols={2} breakpoints={[{ maxWidth: 'md', cols: 1 }]}>
                                          <Text fw={700}>
                                            Our Learners have basic knowledge of english and laptop skills
                                            to complete the course. {mandonaly}
                                          </Text>
                                          <Flex className="custom_radio flex_align_end flex_justify_end md_flex_justify_center">
                                            <Text className="strong_agree" fw={500} ta="center" mx={10}>
                                              Strongly<br></br>Agree
                                            </Text>
                                            <Radio.Group
                                              name="section4_knowledge"
                                              {...evaluationForm.getInputProps('section4_knowledge')}
                                            >
                                              <Group mt="xs">
                                                <Radio value="6" label="6" />
                                                <Radio value="5" label="5" />
                                                <Radio value="4" label="4" />
                                                <Radio value="3" label="3" />
                                                <Radio value="2" label="2" />
                                                <Radio value="1" label="1" />
                                              </Group>
                                            </Radio.Group>
                                            <Text
                                              className="strong_disagree"
                                              fw={500}
                                              ta="center"
                                              mx={10}
                                            >
                                              Strongly<br></br>Disagree
                                            </Text>
                                          </Flex>
                                        </SimpleGrid>
                                        {evaluationForm.errors.section4_knowledge && (
                                          <p style={{ color: 'red' }}>
                                            {evaluationForm.errors.section4_knowledge}
                                          </p>
                                        )}
                                      </Box>
                                    </Flex>
                                    {/* Q4 */}
                                    <Flex
                                      w="100%"
                                      p={20}
                                      my={10}
                                      direction="row"
                                      wrap="wrap"
                                      className="question_box"
                                    >
                                      <Box w="100%" my={20}>
                                        <SimpleGrid cols={2} breakpoints={[{ maxWidth: 'md', cols: 1 }]}>
                                          <Text fw={700}>
                                            Our facilities and cleanliness of the classroom are well
                                            maintained. {mandonaly}
                                          </Text>
                                          <Flex className="custom_radio flex_align_end flex_justify_end md_flex_justify_center">
                                            <Text className="strong_agree" fw={500} ta="center" mx={10}>
                                              Strongly<br></br>Agree
                                            </Text>
                                            <Radio.Group
                                              name="section4_facilities"
                                              {...evaluationForm.getInputProps('section4_facilities')}
                                            >
                                              <Group mt="xs">
                                                <Radio value="6" label="6" />
                                                <Radio value="5" label="5" />
                                                <Radio value="4" label="4" />
                                                <Radio value="3" label="3" />
                                                <Radio value="2" label="2" />
                                                <Radio value="1" label="1" />
                                              </Group>
                                            </Radio.Group>
                                            <Text
                                              className="strong_disagree"
                                              fw={500}
                                              ta="center"
                                              mx={10}
                                            >
                                              Strongly<br></br>Disagree
                                            </Text>
                                          </Flex>
                                        </SimpleGrid>
                                        {evaluationForm.errors.section4_facilities && (
                                          <p style={{ color: 'red' }}>
                                            {evaluationForm.errors.section4_facilities}
                                          </p>
                                        )}
                                      </Box>
                                    </Flex>
                                    {/* Q5 */}
                                    <Flex
                                      w="100%"
                                      p={20}
                                      my={10}
                                      direction="row"
                                      wrap="wrap"
                                      className="question_box"
                                    >
                                      <Box w="100%" my={20}>
                                        <SimpleGrid cols={2} breakpoints={[{ maxWidth: 'md', cols: 1 }]}>
                                          <Text fw={700}>Other Comments or Feedbacks.</Text>
                                          <Flex className="custom_radio flex_align_end flex_justify_end md_flex_justify_center">
                                            <Textarea
                                              size="md"
                                              name="section4_comments"
                                              placeholder="Your Feedback"
                                              w="450px"
                                              {...evaluationForm.getInputProps('section4_comments')}
                                            ></Textarea>
                                          </Flex>
                                        </SimpleGrid>
                                      </Box>
                                    </Flex>
                                  </Box>
                                </Flex>
                              ) : (
                                <Box>

                                  <Box className="detail_box">
                                    {courseDetail.data && courseDetail.data.ts && courseDetail.data.ts.map((item: any) => {
                                        const ts_id = item.ts_id;
                                        const day = item.day;
                                        const user_name = item.user_name;
                                        const isError = tsWithErrors.has(ts_id);

                                        return (
                                          <Button
                                            key={item.id}
                                            variant="outline"
                                            m={4}
                                            onClick={() => handleTSTabChange(`Day${ts_id}`)}
                                            className={`${activeTSTab === `Day${ts_id}` ? 'active' : ''} ${isError ? 'error' : ''}`}
                                          >
                                            {item.day_string ? (
                                                <>
                                                  {user_name} ({item.day_string})
                                                </>
                                            ) : (
                                                <>
                                                  {user_name}
                                                </>
                                            )}
                                          </Button>
                                        );
                                      })}
                                  </Box>

                                  {courseDetail &&
                                    courseDetail.data &&
                                    courseDetail.data.ts.map(function (item: any, i: any) {
                                      return (
                                        <Flex key={item.id}>
                                          {activeTSTab === `Day${item.ts_id}` && (
                                            <Box>
                                              <Box>
                                                {/* S1 - Q1 */}
                                                <Flex
                                                  w="100%"
                                                  p={20}
                                                  my={10}
                                                  direction="row"
                                                  wrap="wrap"
                                                  className="question_box"
                                                >
                                                  <Title order={4} my={10} w="100%" color="#8c4799">
                                                    Section 1 - Professionalism 
                                                  </Title>
                                                  <Text>
                                                    Tell us more about the experience you have had by answering the
                                                    questions below.
                                                  </Text>
                                                  <Box w="100%" my={20}>
                                                    <SimpleGrid cols={2} breakpoints={[{ maxWidth: 'md', cols: 1 }]}>
                                                      <Text fw={700}>
                                                      The TS exhibited a professional attitude, reflected in both their behavior and attire.{mandonaly}
                                                      </Text>
                                                      <Flex className="custom_radio flex_align_end flex_justify_end md_flex_justify_center">
                                                        <Text className="strong_agree" fw={500} ta="center" mx={10}>
                                                          Strongly<br></br>Agree
                                                        </Text>
                                                        <Radio.Group
                                                          name={`section1_ts_attitude${item.ts_id}`}
                                                          {...evaluationForm.getInputProps(`section1_ts_attitude${item.ts_id}`)}
                                                        >
                                                          <Group mt="xs">
                                                            <Radio value="6" label="6" />
                                                            <Radio value="5" label="5" />
                                                            <Radio value="4" label="4" />
                                                            <Radio value="3" label="3" />
                                                            <Radio value="2" label="2" />
                                                            <Radio value="1" label="1" />
                                                          </Group>
                                                        </Radio.Group>
                                                        <Text
                                                          className="strong_disagree"
                                                          fw={500}
                                                          ta="center"
                                                          mx={10}
                                                        >
                                                          Strongly<br></br>Disagree
                                                        </Text>
                                                      </Flex>
                                                    </SimpleGrid>
                                                    {evaluationForm.errors[`section1_ts_attitude${item.ts_id}`] && (
                                                      <p style={{ color: 'red' }}>{evaluationForm.errors[`section1_ts_attitude${item.ts_id}`]}</p>
                                                    )}
                                                  </Box>
                                                </Flex>
                                                {/* S1 - Q2 */}
                                                <Flex
                                                  w="100%"
                                                  p={20}
                                                  my={10}
                                                  direction="row"
                                                  wrap="wrap"
                                                  className="question_box"
                                                >
                                                  <Box w="100%" my={20}>
                                                    <SimpleGrid cols={2} breakpoints={[{ maxWidth: 'md', cols: 1 }]}>
                                                      <Text fw={700}>
                                                        TS is friendly and supportive during the class. {mandonaly}
                                                      </Text>
                                                      <Flex className="custom_radio flex_align_end flex_justify_end md_flex_justify_center">
                                                        <Text className="strong_agree" fw={500} ta="center" mx={10}>
                                                          Strongly<br></br>Agree
                                                        </Text>
                                                        <Radio.Group
                                                          name={`section1_ts_supportive${item.ts_id}`}
                                                          {...evaluationForm.getInputProps(`section1_ts_supportive${item.ts_id}`)}
                                                        >
                                                          <Group mt="xs">
                                                            <Radio value="6" label="6" />
                                                            <Radio value="5" label="5" />
                                                            <Radio value="4" label="4" />
                                                            <Radio value="3" label="3" />
                                                            <Radio value="2" label="2" />
                                                            <Radio value="1" label="1" />
                                                          </Group>
                                                        </Radio.Group>
                                                        <Text
                                                          className="strong_disagree"
                                                          fw={500}
                                                          ta="center"
                                                          mx={10}
                                                        >
                                                          Strongly<br></br>Disagree
                                                        </Text>
                                                      </Flex>
                                                    </SimpleGrid>
                                                    {evaluationForm.errors[`section1_ts_supportive${item.ts_id}`] && (
                                                      <p style={{ color: 'red' }}>{evaluationForm.errors[`section1_ts_supportive${item.ts_id}`]}</p>
                                                    )}
                                                  </Box>
                                                </Flex>
                                              </Box>

                                              <Box>
                                                {/* S2 - Q1 */}
                                                <Flex
                                                  w="100%"
                                                  p={20}
                                                  my={10}
                                                  direction="row"
                                                  wrap="wrap"
                                                  className="question_box"
                                                >
                                                  <Title order={4} my={10} w="100%" color="#8c4799">
                                                    Section 2 - Class Operations
                                                  </Title>
                                                  <Text>
                                                    Tell us more about the experience you have had by answering the
                                                    questions below.
                                                  </Text>
                                                  <Box w="100%" my={20}>
                                                    <SimpleGrid cols={2} breakpoints={[{ maxWidth: 'md', cols: 1 }]}>
                                                      <Text fw={700}>
                                                      Course materials and laptops (if applicable) were well prepared by the TS prior to the class.
                                                      </Text>
                                                      <Flex className="custom_radio flex_align_end flex_justify_end md_flex_justify_center">
                                                        <Text className="strong_agree" fw={500} ta="center" mx={10}>
                                                          Strongly<br></br>Agree
                                                        </Text>
                                                        <Radio.Group
                                                          name={`section2_materials${item.ts_id}`}
                                                          {...evaluationForm.getInputProps(`section2_materials${item.ts_id}`)}
                                                        >
                                                          <Group mt="xs">
                                                            <Radio value="6" label="6" />
                                                            <Radio value="5" label="5" />
                                                            <Radio value="4" label="4" />
                                                            <Radio value="3" label="3" />
                                                            <Radio value="2" label="2" />
                                                            <Radio value="1" label="1" />
                                                          </Group>
                                                        </Radio.Group>
                                                        <Text
                                                          className="strong_disagree"
                                                          fw={500}
                                                          ta="center"
                                                          mx={10}
                                                        >
                                                          Strongly<br></br>Disagree
                                                        </Text>
                                                      </Flex>
                                                    </SimpleGrid>
                                                    {evaluationForm.errors[`section2_materials${item.ts_id}`] && (
                                                      <p style={{ color: 'red' }}>{evaluationForm.errors[`section2_materials${item.ts_id}`]}</p>
                                                    )}
                                                  </Box>
                                                </Flex>
                                                {/* Q2 */}
                                                <Flex
                                                  w="100%"
                                                  p={20}
                                                  my={10}
                                                  direction="row"
                                                  wrap="wrap"
                                                  className="question_box"
                                                >
                                                  <Box w="100%" my={20}>
                                                    <SimpleGrid cols={2} breakpoints={[{ maxWidth: 'md', cols: 1 }]}>
                                                      <Text fw={700}>
                                                        Attendance taking sessions are conducted smoothly with the assistance of the TS. {mandonaly}
                                                      </Text>
                                                      <Flex className="custom_radio flex_align_end flex_justify_end md_flex_justify_center">
                                                        <Text className="strong_agree" fw={500} ta="center" mx={10}>
                                                          Strongly<br></br>Agree
                                                        </Text>
                                                        <Radio.Group
                                                          name={`section2_smoothly${item.ts_id}`}
                                                          {...evaluationForm.getInputProps(`section2_smoothly${item.ts_id}`)}
                                                        >
                                                          <Group mt="xs">
                                                            <Radio value="6" label="6" />
                                                            <Radio value="5" label="5" />
                                                            <Radio value="4" label="4" />
                                                            <Radio value="3" label="3" />
                                                            <Radio value="2" label="2" />
                                                            <Radio value="1" label="1" />
                                                          </Group>
                                                        </Radio.Group>
                                                        <Text
                                                          className="strong_disagree"
                                                          fw={500}
                                                          ta="center"
                                                          mx={10}
                                                        >
                                                          Strongly<br></br>Disagree
                                                        </Text>
                                                      </Flex>
                                                    </SimpleGrid>
                                                    {evaluationForm.errors[`section2_smoothly${item.ts_id}`] && (
                                                      <p style={{ color: 'red' }}>{evaluationForm.errors[`section2_smoothly${item.ts_id}`]}</p>
                                                    )}
                                                  </Box>
                                                </Flex>
                                                {/* Q3 */}
                                                <Flex
                                                  w="100%"
                                                  p={20}
                                                  my={10}
                                                  direction="row"
                                                  wrap="wrap"
                                                  className="question_box"
                                                >
                                                  <Box w="100%" my={20}>
                                                    <SimpleGrid cols={2} breakpoints={[{ maxWidth: 'md', cols: 1 }]}>
                                                      <Text fw={700}>
                                                        Administration of TRAQOM. Google Reviews and Class Photo taking was efficiently supported by the TS. {mandonaly}
                                                      </Text>
                                                      <Flex className="custom_radio flex_align_end flex_justify_end md_flex_justify_center">
                                                        <Text className="strong_agree" fw={500} ta="center" mx={10}>
                                                          Strongly<br></br>Agree
                                                        </Text>
                                                        <Radio.Group
                                                          name={`section2_efficiently${item.ts_id}`}
                                                          {...evaluationForm.getInputProps(`section2_efficiently${item.ts_id}`)}
                                                        >
                                                          <Group mt="xs">
                                                            <Radio value="6" label="6" />
                                                            <Radio value="5" label="5" />
                                                            <Radio value="4" label="4" />
                                                            <Radio value="3" label="3" />
                                                            <Radio value="2" label="2" />
                                                            <Radio value="1" label="1" />
                                                          </Group>
                                                        </Radio.Group>
                                                        <Text
                                                          className="strong_disagree"
                                                          fw={500}
                                                          ta="center"
                                                          mx={10}
                                                        >
                                                          Strongly<br></br>Disagree
                                                        </Text>
                                                      </Flex>
                                                    </SimpleGrid>
                                                    {evaluationForm.errors[`section2_efficiently${item.ts_id}`] && (
                                                      <p style={{ color: 'red' }}>{evaluationForm.errors[`section2_efficiently${item.ts_id}`]}</p>
                                                    )}
                                                  </Box>
                                                </Flex>
                                              </Box>

                                              <Box>
                                                {/* S3 - Q1 */}
                                                <Flex
                                                  w="100%"
                                                  p={20}
                                                  my={10}
                                                  direction="row"
                                                  wrap="wrap"
                                                  className="question_box"
                                                >
                                                  <Title order={4} my={10} w="100%" color="#8c4799">
                                                    Section 3 - Initiative 
                                                  </Title>
                                                  <Text>
                                                    Tell us more about the experience you have had by answering the
                                                    questions below.
                                                  </Text>
                                                  <Box w="100%" my={20}>
                                                    <SimpleGrid cols={2} breakpoints={[{ maxWidth: 'md', cols: 1 }]}>
                                                      <Text fw={700}>
                                                        When you refer learners to the TS regarding their feedbacks (e.g., unhappy learners, language barriers), issues are resolved promptly. {mandonaly}
                                                      </Text>
                                                      <Flex className="custom_radio flex_align_end flex_justify_end md_flex_justify_center">
                                                        <Text className="strong_agree" fw={500} ta="center" mx={10}>
                                                          Strongly<br></br>Agree
                                                        </Text>
                                                        <Radio.Group
                                                          name={`section3_feedbacks${item.ts_id}`}
                                                          {...evaluationForm.getInputProps(`section3_feedbacks${item.ts_id}`)}
                                                        >
                                                          <Group mt="xs">
                                                            <Radio value="6" label="6" />
                                                            <Radio value="5" label="5" />
                                                            <Radio value="4" label="4" />
                                                            <Radio value="3" label="3" />
                                                            <Radio value="2" label="2" />
                                                            <Radio value="1" label="1" />
                                                          </Group>
                                                        </Radio.Group>
                                                        <Text
                                                          className="strong_disagree"
                                                          fw={500}
                                                          ta="center"
                                                          mx={10}
                                                        >
                                                          Strongly<br></br>Disagree
                                                        </Text>
                                                      </Flex>
                                                    </SimpleGrid>
                                                    {evaluationForm.errors[`section3_feedbacks${item.ts_id}`] && (
                                                      <p style={{ color: 'red' }}>{evaluationForm.errors[`section3_feedbacks${item.ts_id}`]}</p>
                                                    )}
                                                  </Box>
                                                </Flex>
                                                {/* Q2 */}
                                                <Flex
                                                  w="100%"
                                                  p={20}
                                                  my={10}
                                                  direction="row"
                                                  wrap="wrap"
                                                  className="question_box"
                                                >
                                                  <Box w="100%" my={20}>
                                                    <SimpleGrid cols={2} breakpoints={[{ maxWidth: 'md', cols: 1 }]}>
                                                      <Text fw={700}>
                                                        TS took the initiative to help learners who requires technical support during the class. {mandonaly}
                                                      </Text>
                                                      <Flex className="custom_radio flex_align_end flex_justify_end md_flex_justify_center">
                                                        <Text className="strong_agree" fw={500} ta="center" mx={10}>
                                                          Strongly<br></br>Agree
                                                        </Text>
                                                        <Radio.Group
                                                          name={`section3_help${item.ts_id}`}
                                                          {...evaluationForm.getInputProps(`section3_help${item.ts_id}`)}
                                                        >
                                                          <Group mt="xs">
                                                            <Radio value="6" label="6" />
                                                            <Radio value="5" label="5" />
                                                            <Radio value="4" label="4" />
                                                            <Radio value="3" label="3" />
                                                            <Radio value="2" label="2" />
                                                            <Radio value="1" label="1" />
                                                          </Group>
                                                        </Radio.Group>
                                                        <Text
                                                          className="strong_disagree"
                                                          fw={500}
                                                          ta="center"
                                                          mx={10}
                                                        >
                                                          Strongly<br></br>Disagree
                                                        </Text>
                                                      </Flex>
                                                    </SimpleGrid>
                                                    {evaluationForm.errors[`section3_help${item.ts_id}`] && (
                                                      <p style={{ color: 'red' }}>{evaluationForm.errors[`section3_help${item.ts_id}`]}</p>
                                                    )}
                                                  </Box>
                                                </Flex>
                                                {/* Q3 */}
                                                <Flex
                                                  w="100%"
                                                  p={20}
                                                  my={10}
                                                  direction="row"
                                                  wrap="wrap"
                                                  className="question_box"
                                                >
                                                  <Box w="100%" my={20}>
                                                    <SimpleGrid cols={2} breakpoints={[{ maxWidth: 'md', cols: 1 }]}>
                                                      <Text fw={700}>
                                                        A broad range of learners are assisted by the TS, rather than focusing only on one vulnerable learner. {mandonaly}
                                                      </Text>
                                                      <Flex className="custom_radio flex_align_end flex_justify_end md_flex_justify_center">
                                                        <Text className="strong_agree" fw={500} ta="center" mx={10}>
                                                          Strongly<br></br>Agree
                                                        </Text>
                                                        <Radio.Group
                                                          name={`section3_assisted${item.ts_id}`}
                                                          {...evaluationForm.getInputProps(`section3_assisted${item.ts_id}`)}
                                                        >
                                                          <Group mt="xs">
                                                            <Radio value="6" label="6" />
                                                            <Radio value="5" label="5" />
                                                            <Radio value="4" label="4" />
                                                            <Radio value="3" label="3" />
                                                            <Radio value="2" label="2" />
                                                            <Radio value="1" label="1" />
                                                          </Group>
                                                        </Radio.Group>
                                                        <Text
                                                          className="strong_disagree"
                                                          fw={500}
                                                          ta="center"
                                                          mx={10}
                                                        >
                                                          Strongly<br></br>Disagree
                                                        </Text>
                                                      </Flex>
                                                    </SimpleGrid>
                                                    {evaluationForm.errors[`section3_assisted${item.ts_id}`] && (
                                                      <p style={{ color: 'red' }}>{evaluationForm.errors[`section3_assisted${item.ts_id}`]}</p>
                                                    )}
                                                  </Box>
                                                </Flex>
                                                {/* Q4 */}
                                                <Flex
                                                  w="100%"
                                                  p={20}
                                                  my={10}
                                                  direction="row"
                                                  wrap="wrap"
                                                  className="question_box"
                                                >
                                                  <Box w="100%" my={20}>
                                                    <SimpleGrid cols={2} breakpoints={[{ maxWidth: 'md', cols: 1 }]}>
                                                      <Text fw={700}>
                                                        TS assisted you effectively in your learning activities. {mandonaly}
                                                      </Text>
                                                      <Flex className="custom_radio flex_align_end flex_justify_end md_flex_justify_center">
                                                        <Text className="strong_agree" fw={500} ta="center" mx={10}>
                                                          Strongly<br></br>Agree
                                                        </Text>
                                                        <Radio.Group
                                                          name={`section3_ts_effectively${item.ts_id}`}
                                                          {...evaluationForm.getInputProps(`section3_ts_effectively${item.ts_id}`)}
                                                        >
                                                          <Group mt="xs">
                                                            <Radio value="6" label="6" />
                                                            <Radio value="5" label="5" />
                                                            <Radio value="4" label="4" />
                                                            <Radio value="3" label="3" />
                                                            <Radio value="2" label="2" />
                                                            <Radio value="1" label="1" />
                                                          </Group>
                                                        </Radio.Group>
                                                        <Text
                                                          className="strong_disagree"
                                                          fw={500}
                                                          ta="center"
                                                          mx={10}
                                                        >
                                                          Strongly<br></br>Disagree
                                                        </Text>
                                                      </Flex>
                                                    </SimpleGrid>
                                                    {evaluationForm.errors[`section3_ts_effectively${item.ts_id}`] && (
                                                      <p style={{ color: 'red' }}>{evaluationForm.errors[`section3_ts_effectively${item.ts_id}`]}</p>
                                                    )}
                                                  </Box>
                                                </Flex>
                                              </Box>
                                              
                                            </Box>
                                          )}
                                        </Flex>
                                      );
                                  })}

                                  <Box>
                                    {/* S4 - Q1 */}
                                    <Flex
                                      w="100%"
                                      p={20}
                                      my={10}
                                      direction="row"
                                      wrap="wrap"
                                      className="question_box"
                                    >
                                      <Title order={4} my={10} w="100%" color="#8c4799">
                                        Section 4 - Others
                                      </Title>
                                      <Text>
                                        Tell us more about the experience you have had by answering the
                                        questions below.
                                      </Text>
                                    </Flex>
                                    {/* Q2 */}
                                    <Flex
                                      w="100%"
                                      p={20}
                                      my={10}
                                      direction="row"
                                      wrap="wrap"
                                      className="question_box"
                                    >
                                      <Box w="100%" my={20}>
                                        <SimpleGrid cols={2} breakpoints={[{ maxWidth: 'md', cols: 1 }]}>
                                          <Text fw={700}>
                                            Our Course Content is relevant and up to date. {mandonaly}
                                          </Text>
                                          <Flex className="custom_radio flex_align_end flex_justify_end md_flex_justify_center">
                                            <Text className="strong_agree" fw={500} ta="center" mx={10}>
                                              Strongly<br></br>Agree
                                            </Text>
                                            <Radio.Group
                                              name="section4_relevant"
                                              {...evaluationForm.getInputProps('section4_relevant')}
                                            >
                                              <Group mt="xs">
                                                <Radio value="6" label="6" />
                                                <Radio value="5" label="5" />
                                                <Radio value="4" label="4" />
                                                <Radio value="3" label="3" />
                                                <Radio value="2" label="2" />
                                                <Radio value="1" label="1" />
                                              </Group>
                                            </Radio.Group>
                                            <Text
                                              className="strong_disagree"
                                              fw={500}
                                              ta="center"
                                              mx={10}
                                            >
                                              Strongly<br></br>Disagree
                                            </Text>
                                          </Flex>
                                        </SimpleGrid>
                                        {evaluationForm.errors.section4_relevant && (
                                          <p style={{ color: 'red' }}>
                                            {evaluationForm.errors.section4_relevant}
                                          </p>
                                        )}
                                      </Box>
                                    </Flex>
                                    {/* Q3 */}
                                    <Flex
                                      w="100%"
                                      p={20}
                                      my={10}
                                      direction="row"
                                      wrap="wrap"
                                      className="question_box"
                                    >
                                      <Box w="100%" my={20}>
                                        <SimpleGrid cols={2} breakpoints={[{ maxWidth: 'md', cols: 1 }]}>
                                          <Text fw={700}>
                                            Our Learners have basic knowledge of english and laptop skills
                                            to complete the course. {mandonaly}
                                          </Text>
                                          <Flex className="custom_radio flex_align_end flex_justify_end md_flex_justify_center">
                                            <Text className="strong_agree" fw={500} ta="center" mx={10}>
                                              Strongly<br></br>Agree
                                            </Text>
                                            <Radio.Group
                                              name="section4_knowledge"
                                              {...evaluationForm.getInputProps('section4_knowledge')}
                                            >
                                              <Group mt="xs">
                                                <Radio value="6" label="6" />
                                                <Radio value="5" label="5" />
                                                <Radio value="4" label="4" />
                                                <Radio value="3" label="3" />
                                                <Radio value="2" label="2" />
                                                <Radio value="1" label="1" />
                                              </Group>
                                            </Radio.Group>
                                            <Text
                                              className="strong_disagree"
                                              fw={500}
                                              ta="center"
                                              mx={10}
                                            >
                                              Strongly<br></br>Disagree
                                            </Text>
                                          </Flex>
                                        </SimpleGrid>
                                        {evaluationForm.errors.section4_knowledge && (
                                          <p style={{ color: 'red' }}>
                                            {evaluationForm.errors.section4_knowledge}
                                          </p>
                                        )}
                                      </Box>
                                    </Flex>
                                    {/* Q4 */}
                                    <Flex
                                      w="100%"
                                      p={20}
                                      my={10}
                                      direction="row"
                                      wrap="wrap"
                                      className="question_box"
                                    >
                                      <Box w="100%" my={20}>
                                        <SimpleGrid cols={2} breakpoints={[{ maxWidth: 'md', cols: 1 }]}>
                                          <Text fw={700}>
                                            Our facilities and cleanliness of the classroom are well
                                            maintained. {mandonaly}
                                          </Text>
                                          <Flex className="custom_radio flex_align_end flex_justify_end md_flex_justify_center">
                                            <Text className="strong_agree" fw={500} ta="center" mx={10}>
                                              Strongly<br></br>Agree
                                            </Text>
                                            <Radio.Group
                                              name="section4_facilities"
                                              {...evaluationForm.getInputProps('section4_facilities')}
                                            >
                                              <Group mt="xs">
                                                <Radio value="6" label="6" />
                                                <Radio value="5" label="5" />
                                                <Radio value="4" label="4" />
                                                <Radio value="3" label="3" />
                                                <Radio value="2" label="2" />
                                                <Radio value="1" label="1" />
                                              </Group>
                                            </Radio.Group>
                                            <Text
                                              className="strong_disagree"
                                              fw={500}
                                              ta="center"
                                              mx={10}
                                            >
                                              Strongly<br></br>Disagree
                                            </Text>
                                          </Flex>
                                        </SimpleGrid>
                                        {evaluationForm.errors.section4_facilities && (
                                          <p style={{ color: 'red' }}>
                                            {evaluationForm.errors.section4_facilities}
                                          </p>
                                        )}
                                      </Box>
                                    </Flex>
                                    {/* Q5 */}
                                    <Flex
                                      w="100%"
                                      p={20}
                                      my={10}
                                      direction="row"
                                      wrap="wrap"
                                      className="question_box"
                                    >
                                      <Box w="100%" my={20}>
                                        <SimpleGrid cols={2} breakpoints={[{ maxWidth: 'md', cols: 1 }]}>
                                          <Text fw={700}>Other Comments or Feedbacks.</Text>
                                          <Flex className="custom_radio flex_align_end flex_justify_end md_flex_justify_center">
                                            <Textarea
                                              size="md"
                                              name="section4_comments"
                                              placeholder="Your Feedback"
                                              w="450px"
                                              {...evaluationForm.getInputProps('section4_comments')}
                                            ></Textarea>
                                          </Flex>
                                        </SimpleGrid>
                                      </Box>
                                    </Flex>
                                  </Box>
                                </Box>
                              )}


                              {courseDetail.data && courseDetail.data.trainer_evaluations_count ? (
                                <Flex w="100%"></Flex>
                              ) : (
                                <Flex w="100%">
                                  <Flex className="flex_justify_end" w="100%">
                                    <GradientButton type="submit" mt="sm">
                                      Submit
                                    </GradientButton>
                                  </Flex>
                                </Flex>
                              )}
                            </form>
                          </Stepper.Step>
                          <Stepper.Step>
                            <Box bg="#e5f7f2" p={20} className="text-center">
                              <Title order={3} ta="center">
                                Thank you for your feedback
                              </Title>
                              <Text p={20} ta="center">
                                We will consider all inputs and suggestions with the aim of
                                improving our programmes and enhancing our service delivery. We look
                                forward to seeing you in other FirstCom Academy programmes.
                              </Text>
                              <Button onClick={prevStep} style={{ color: 'gray' }} mt="sm">
                                Back
                              </Button>
                            </Box>
                          </Stepper.Step>
                        </Stepper>
                      </Flex>
                    </Box>
                  )}
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Stack>
  );
}
