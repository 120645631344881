import { createStyles } from '@mantine/core';
import { DataTableProps, DataTable as MantineDataTable } from 'mantine-datatable';

export function DataTable<T = unknown>(props: DataTableProps<T>) {
  const { classes } = useStyles();

  return (
    <MantineDataTable
      scrollAreaProps={{ type: 'always', style: { paddingBottom: 25 } }}
      classNames={classes}
      minHeight={300}
      withBorder={false}
      withColumnBorders={false}
      rowBorderColor={'transparent'}
      striped
      highlightOnHover
      {...props}
    />
  );
}

const useStyles = createStyles((theme) => ({
  root: {
    '& td': {
      verticalAlign: 'middle',
    },
  },
  header: {
    '& th': {
      backgroundColor: '#F5FAFF',
      verticalAlign: 'middle',
    },
    '& thead': {
      borderBottomWidth: 0,
    },
    '& th div': {
      fontSize: '0.95em',
      textOverflow: 'unset',
      whiteSpace: 'break-spaces',
      color: 'black',
      fontWeight: 1900,
      lineHeight: 1,
    },
  },
}));
